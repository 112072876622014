import 'plyr-react/dist/plyr.css'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumb, BreadcrumbItem, Col, Container, Row, Spinner } from 'reactstrap'
import styled from 'styled-components'
import Layout from '../../components/common/Layout'
import SEO from '../../components/common/Seo'
import SeriesCard from '../../components/home/SeriesCard'
import legacyApi from '../../services/api/legacyApi'
import media from '../../styles/media'
import mixins from '../../styles/mixins'
import theme from '../../styles/theme'

const { colors } = theme

const StyledGrid = styled(Container)`
  .row {
    ${media.tinyDesktop`
      display: block;
    `}
  }
  .col-3 {
    ${media.tinyDesktop`
      max-width: 100%;
    `}
  }
  .col-4 {
    ${media.tinyDesktop`
      max-width: 100%;
    `}
  }
  .grid-border-left {
    border-left: 1px solid ${colors.lightShade};
  }
`
const Main = styled.div`
  ${mixins.sidePadding}
  ${mixins.desktopAlignCenter}
  padding-top: 60px;

  .breadcrumb {
    background-color: ${colors.light};
    font-size: 16px;
  }

  .page-title {
    font-size: 34px;
    color: ${colors.black};
    margin: 32px 0 16px;
  }
  .page-description {
    font-size: 16px;
    color: ${colors.gray};
  }
`

const SeriesListeningPage = () => {
  const [loading, setLoading] = useState(true)
  const [seriesListing, setSeriesListing] = useState([])

  useEffect(() => {
    legacyApi
      .post('', {
        op: 'seriesGetListing',
      })
      .then(res => {
        setSeriesListing(res.data)
        setLoading(false)
      })
      .catch(() => {})
  }, [])
  return (
    <React.Fragment>
      {loading ? (
        <div style={{ textAlign: 'center', margin: '36px', height: '100vh' }}>
          <Spinner color='secondary' />
        </div>
      ) : (
        <Layout>
          <Main>
            <StyledGrid fluid>
              <Row>
                <Col>
                  <Breadcrumb tag='nav' listTag='div'>
                    <BreadcrumbItem>
                      <Link to='/'>Início</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Caminhos da oração</BreadcrumbItem>
                  </Breadcrumb>
                  <h1 className='page-title'>Inicie conosco a sua oração!</h1>
                  {seriesListing && seriesListing.length > 0 ? (
                    seriesListing.map(item => (
                      <SeriesCard seriesTitle={item.series_title} seriesId={item.series_id} key={item.series_id} />
                    ))
                  ) : (
                    <SeriesCard
                      seriesDescription={'No momento não temos nenhum caminho de oração. Tente novamente outra hora'}
                    />
                  )}
                </Col>
              </Row>
            </StyledGrid>
          </Main>
        </Layout>
      )}
      <SEO title={`Trilha de oração - ${process.env.REACT_APP_BRAND_NAME_SHORT}`} pathname={`/caminhos`} />
    </React.Fragment>
  )
}

export default SeriesListeningPage
