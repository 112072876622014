import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useAtom } from 'jotai'
import { donationData } from '../../services/state/donationPurchaseData'

import GlobalButton from '../common/GlobalButton'

import mixins from '../../styles/mixins'
import theme from '../../styles/theme'

const { colors } = theme

const Main = styled.div`
  ${mixins.rewardDonationCard};
`

const PaymentMethodItem = ({ paymentMethodTitle, paymentMethodDescription, paymentMethodOptions, paymentMethodId }) => {
  const history = useHistory()
  const [donationPurchaseData, setDonationPurchaseData] = useAtom(donationData)

  // Sends paymentMethod to the state and redirects the user to the payment form page
  const onPaymentMethodClick = function (methodId) {
    history.push(`/pagamento?active-step=payment-data&payment-method=${methodId}`)

    setDonationPurchaseData({
      ...donationPurchaseData,
      paymentMethod: methodId,
      paymentMethodOptions
    })
  }

  return (
    <Main>
      <div className='visible-section'>
        <div>
          <h3 className='card-title'>{paymentMethodTitle}</h3>
          <p className='card-description'>{paymentMethodDescription}</p>
        </div>
      </div>
      <div style={{ marginTop: '26px' }}>
        <GlobalButton
          buttonLabel={`Pagar com ${paymentMethodTitle}`}
          large={false}
          dropShadow={true}
          background={colors.primary}
          labelColor={colors.light}
          borderColor={colors.primary}
          hoverColor={colors.primaryShade}
          onClick={() => onPaymentMethodClick(paymentMethodId)}
        />
      </div>
    </Main>
  )
}

export default PaymentMethodItem
