import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { logoutUser } from '../../services/utils/user'
import OneSignal, { useOneSignalSetup } from 'react-onesignal';
import { useAtom } from 'jotai'
import { userData } from '../../services/state/appState'

import theme from '../../styles/theme'

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'
import userSilhouette from '../../assets/imgs/silhouette-user.png'

const { colors } = theme

const StyledDropdown = styled(Dropdown)`
  button {
    font-size: 14px;
    color: ${colors.primary};
    background-color: ${colors.background};
    border-color: ${colors.background};
    :hover,
    :focus {
      color: ${colors.primary};
      background-color: ${colors.light};
      border-color: ${colors.light};
    }
  }
  .user-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  p {
    position: relative;
    margin-left: 10px;
    top: 5px;
  }
`

const UserBadge = ({ userName, userImage }) => {
  const history = useHistory()

  const [atomUserData, setUserData] = useAtom(userData)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [hasProfilePic, setHasProfilePic] = useState(false)
  const toggle = () => setDropdownOpen(prevState => !prevState)

  useEffect(() => {
    if (userImage === '/assets/imgs/silhouette-user.png') {
      setHasProfilePic(false)
    } else {
      setHasProfilePic(true)
    }
  }, [userImage])

  useOneSignalSetup(() => {
    OneSignal.setEmail(atomUserData.email)
    OneSignal.setExternalUserId(atomUserData.id)
  })

  const userLogout = function () {
    logoutUser()
    setUserData(null)
    history.push('/')
  }

  return (
    <StyledDropdown direction='down' isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle>
        <div style={{ display: 'flex', alignContent: 'center' }}>
          <img className='user-img' src={hasProfilePic ? userImage : userSilhouette} alt='Imagem de perfil' />
          <p>{userName}</p>
        </div>
      </DropdownToggle>
      <DropdownMenu>
        {atomUserData.toKickstart === true ? null : (
          <Link to='/editar-perfil'><DropdownItem>Editar Perfil</DropdownItem></Link>
        )}
        <DropdownItem onClick={() => userLogout()}>Sair</DropdownItem>
      </DropdownMenu>
    </StyledDropdown>
  )
}

export default UserBadge
