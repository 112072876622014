import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'

import { Spinner } from 'reactstrap'
import SEO from '../../components/common/Seo'
import GlobalButton from '../../components/common/GlobalButton'
import api from '../../services/api/api'

import theme from '../../styles/theme'
import mixins from '../../styles/mixins'
import media from '../../styles/media'

const { colors } = theme

const Main = styled.div`
  ${mixins.sidePadding};
  margin: 0 auto;
  margin-top: 64px;
  width: 100%;
  max-width: 800px;
  .body-container {
    ${mixins.markupStyles};
  }
  .header-update {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    span {
      padding-right: 16px;
      font-size: 14px;
      color: ${colors.gray};
    }
  }
`
const UpdateTitle = styled.h1`
  font-size: 38px;
  font-weight: 700;
  color: ${colors.black};
  ${media.tablet` font-size: 34px; `}
`

const ProgressIndividualPost = props => {
  const [postContent, setPostContent] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const formatDate = function (publishedDate) {
    const firstDate = parseISO(publishedDate)
    return format(firstDate, "dd 'de' MMMM' de 'yyyy'", { locale: ptBR })
  }

  useEffect(() => {
    api.get(`progress-posts/${props.match.params.postId}`).then(res => {
      setTimeout(() => {
        setPostContent(res.data)
        setIsLoading(false)
      }, 200)
    }).catch(() => {})
  }, [props.match.params.postId])

  return (
    <React.Fragment>
      {isLoading ? (
        <div style={{ textAlign: 'center', margin: '36px', height: '100vh' }}>
          <Spinner color='secondary' />
        </div>
      ) : postContent ? (
        <React.Fragment>
          <SEO />
          <Main>
            <div style={{ maxWidth: '250px', paddingBottom: '32px' }}>
              <Link to='/progresso'>
                <GlobalButton
                  large={false}
                  dropShadow={false}
                  background={colors.light}
                  labelColor={colors.primary}
                  border={colors.primary}
                  hoverColor={colors.lightShade}
                  buttonLabel='← Todos as atualizações'
                />
              </Link>
            </div>

            <div className='header-update'>
              <span>ATUALIZAÇÃO #{postContent.update_number}</span>
              <span>{formatDate(postContent.frontmatter.date)}</span>
            </div>
            <UpdateTitle>{postContent.frontmatter.title}</UpdateTitle>
            <hr style={{ marginBottom: '36px' }} />

            <div
              className='body-container'
              dangerouslySetInnerHTML={{
                __html: postContent.body,
              }}
            />
          </Main>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  )
}

export default ProgressIndividualPost
