import CpfCnpj from '@react-br-forms/cpf-cnpj-mask'
import { cnpj as CnpjValidator, cpf as CpfValidator } from 'cpf-cnpj-validator'
import { Field, Form, Formik } from 'formik'
import { useAtom } from 'jotai'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import * as Yup from 'yup'

import { donationData } from '../../services/state/donationPurchaseData'
import { validateName } from '../../services/utils/validation'

import GlobalButton from '../common/GlobalButton'

import FormikErrorFocus from 'formik-error-focus'
import { getUser } from '../../services/utils/user'
import media from '../../styles/media'
import mixins from '../../styles/mixins'
import theme from '../../styles/theme'

const { colors } = theme

const StyledForm = styled(Form)`
  ${mixins.formStyles}

  .flex-container {
    display: flex;
    justify-content: space-between;
    ${media.phablet` display: block; `}
  }

  .flex-container-item___left {
    margin-right: 26px;
    ${media.phablet` margin-right: inherit; `}
  }
`

const InstallmentFeeComponent = ({ name, onBlur, value, onChange, recurrency }) => {
  const [donationPurchaseData] = useAtom(donationData)
  const installmentFeeOptions = [
    {
      label: 'Pagar à vista',
      value: 1,
    },
    {
      label: 'Parcelar em 2x',
      value: 2,
    },
    {
      label: 'Parcelar em 3x',
      value: 3,
    },
    {
      label: 'Parcelar em 4x',
      value: 4,
    },
    {
      label: 'Parcelar em 5x',
      value: 5,
    },
  ]

  if (donationPurchaseData.value >= 300) {
    return (
      <React.Fragment>
        <label htmlFor={name}>Parcelamento</label>
        <select name={name} onChange={onChange} onBlur={onBlur} value={value}>
          {recurrency ? <option value={1}>Pagar à vista</option> : null}

          {!recurrency ? (
            <React.Fragment>
              {installmentFeeOptions.map(option => (
                <option value={option.value}>{option.label}</option>
              ))}
            </React.Fragment>
          ) : null}
        </select>
      </React.Fragment>
    )
  } else {
    return null
  }
}

const BoletoForm = () => {
  const history = useHistory()
  const [donationPurchaseData, setDonationPurchaseData] = useAtom(donationData)
  const [transactionRecurrency, setTransactionRecurrency] = useState(false)

  const getUserData = function (prop) {
    if (getUser()[`${prop}`]) {
      return getUser()[`${prop}`]
    } else {
      return false
    }
  }

  const handlePaymentType = e => {
    let donationRecurrentValue = e.currentTarget.value
    setDonationPurchaseData(false)

    if (donationRecurrentValue === 'recurrent') {
      setDonationPurchaseData({
        ...donationPurchaseData,
        donationIsRecurrent: true,
      })
    }

    if (donationRecurrentValue === 'unique') {
      setDonationPurchaseData({
        ...donationPurchaseData,
        donationIsRecurrent: false,
      })
    }
  }

  return (
    <Formik
      initialValues={{
        nome: getUserData('nome') ? getUserData('nome') : '',
        cpf_cnpj: getUserData('cpf') ? getUserData('cpf') : '',
        parcela: 1,
        doacao_recorrente: 'recurrent',
      }}
      validationSchema={Yup.object().shape({
        cpf_cnpj: Yup.string()
          .required('Insira seu CPF/CNPJ')
          .matches(
            /([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})/,
            'Insira um CPF/CNPJ válido.'
          )
          .test('cpf-valido', 'Insira um CPF/CNPJ válido', value => {
            return CpfValidator.isValid(value) || CnpjValidator.isValid(value)
          }),

        doacao_recorrente: Yup.string().required('Selecione com que frequência gostaria de doar'),
        parcela: Yup.number(),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setDonationPurchaseData({
          ...donationPurchaseData,
          paymentMethodData: values,
        })
        setSubmitting(false)
        history.push('/pagamento?active-step=delivery-adress')
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
        <StyledForm onSubmit={handleSubmit}>
          <div className='form-control-container'>
            <label htmlFor='nome'>Nome</label>
            <Field
              type='text'
              inputMode='text'
              name='nome'
              onChange={handleChange}
              onBlur={handleBlur}
              validate={validateName}
              value={values.nome}
            />
            {errors.nome && touched.nome && <div className='validation-error'>{errors.nome}</div>}
          </div>

          <div className='form-control-container'>
            <label htmlFor='nome'>CPF (somente números)</label>
            <CpfCnpj type='tel' name='cpf_cnpj' onChange={handleChange} onBlur={handleBlur} value={values.cpf_cnpj} />
            {errors.cpf_cnpj && touched.cpf_cnpj && <div className='validation-error'>{errors.cpf_cnpj}</div>}
          </div>

          {['pix', 'paypal', 'whatsapp'].indexOf(donationPurchaseData.paymentMethod) === -1 ? (
            <div className='flex-control-container'>
              <InstallmentFeeComponent
                type='number'
                name='parcela'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.parcela}
                recurrency={transactionRecurrency}
              />
            </div>
          ) : null}

          {donationPurchaseData.isBuilderDonation ||
          ['pix', 'paypal', 'whatsapp'].indexOf(donationPurchaseData.paymentMethod) > -1 ? null : (
            <div className='form-control-container'>
              <label htmlFor='doacao_recorrente'>Selecione o tipo de pagamento:</label>
              <select
                value={values.doacao_recorrente}
                name='doacao_recorrente'
                onChange={e => {
                  handleChange(e)
                  let currentRecurrency = e.target.value
                  if (currentRecurrency === 'unique') {
                    setTransactionRecurrency(false)
                  }
                  if (currentRecurrency === 'recurrent') {
                    setTransactionRecurrency(true)
                  }
                  handlePaymentType(e)
                }}
                onBlur={e => {
                  handleBlur(e)
                  handlePaymentType(e)
                }}
                onSubmit={e => {
                  handleSubmit(e)
                  handlePaymentType(e)
                }}
              >
                <option value='recurrent'>Doação Recorrente</option>
                <option value='unique'>Doação Única</option>
              </select>
            </div>
          )}

          <GlobalButton
            large={false}
            dropShadow={true}
            background={colors.primary}
            labelColor={colors.light}
            border={colors.primary}
            hoverColor={colors.primary}
            buttonLabel='Prosseguir com a doação'
            type='submit'
          />

          <FormikErrorFocus offset={0} align={'top'} focusDelay={50} ease={'linear'} duration={500} />
        </StyledForm>
      )}
    </Formik>
  )
}

export default BoletoForm
