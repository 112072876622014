import React, { forwardRef } from 'react'
import { Spinner } from 'reactstrap'
import styled from 'styled-components'
import theme from '../../styles/theme'
const { colors } = theme

const Button = styled.button.attrs(props => ({
  backgroundColor: props.backgroundColor || colors.primary,
  labelColor: props.labelColor || colors.light,
  borderColor: props.borderColor || colors.primary,
  hoverColor: props.hoverColor || colors.primaryShade,
  disabledColor: props.disabledColor || colors.primaryTint,
}))`
  background: ${props => props.backgroundColor};
  color: ${props => props.labelColor};
  border: solid 1px ${props => props.borderColor};
  font-size: ${props => (props.isLarge ? '24px' : '16px')};
  appearance: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
  padding: 15px 0;
  width: 100%;
  text-decoration: none;
  text-align: center;
  transition: 60ms ease-in-out;

  :hover {
    background: ${props => props.hoverColor};
    box-shadow: ${props => (props.hasDropShadow ? colors.buttonDropShadow : 'none')};
  }

  :disabled {
    opacity: 0.65;
    cursor: not-allowed;
    box-shadow: none;
  }
`

const GlobalButton = forwardRef(
  (
    {
      onClick,
      id,
      style,
      type,
      buttonLabel,
      large,
      dropShadow,
      background,
      labelColor,
      hoverColor,
      border,
      disabled,
      disabledColor,
      loading,
    },
    ref
  ) => {
    return (
      <Button
        onClick={onClick}
        id={id}
        ref={ref}
        style={style}
        type={type}
        isLarge={large}
        hasDropShadow={dropShadow}
        backgroundColor={background}
        labelColor={labelColor}
        borderColor={border}
        hoverColor={hoverColor}
        disabled={disabled}
        disabledColor={disabledColor}
      >
        <span>{buttonLabel}</span>
        {loading ? (
          <div style={{ textAlign: 'center',  height: '20px', width: '20px', display: 'inline-block', marginLeft: '10px' }}>
            <Spinner color='white' />
          </div>
        ) : null}
      </Button>
    )
  }
)

export default GlobalButton
