import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import GlobalButton from '../../components/common/GlobalButton'
import Layout from '../../components/common/Layout'
import SEO from '../../components/common/Seo'
import media from '../../styles/media'
import mixins from '../../styles/mixins'
import theme from '../../styles/theme'



const { colors } = theme

const Main = styled.div`
  ${mixins.sidePadding}
  ${mixins.desktopAlignCenter}
  h1 {
    font-size: 32px;
    text-align: center;
    margin: 18px;
    ${media.tablet`
      font-size: 18px;
    `}
  }
  ${media.tablet`
    margin: 0;
    padding: 0;
  `}
`
const ChoiceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: 1100px;
  ${media.tablet`
    display: block;
  `}
  .center-label {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    margin: 12px;
  }
`
const ChoiceCard = styled.div`
  width: 100%;
  padding: 22px;
  border: 2px solid ${colors.lightShade};
  border-radius: 10px;
  margin: 16px;
  ${media.tablet`
     width: initial;
     margin: 0 16px;
   `}

  h2 {
    font-size: 22px;
    margin-bottom: 22px;
  }
  p {
    font-family: Merriweather;
    font-size: 16px;
    color: ${colors.gray};
    margin-bottom: 22px;
  }
`

const ChoiceItem = ({ title, description, buttonLabel, pathname, style }) => {
  return (
    <ChoiceCard style={style}>
      <h2>{title}</h2>
      <p>{description}</p>
      <Link to={pathname}>
        <GlobalButton buttonLabel={buttonLabel} />
      </Link>
    </ChoiceCard>
  )
}

const DonationChoice = () => {
  return (
    <React.Fragment>
      <SEO title='Escolha uma doação - Financiamento Coletivo' />
      <Layout>
        <Main>
          <h1>Escolha como gostaria de contribuir:</h1>
          <ChoiceContainer>
            <ChoiceItem
              title='Doe um valor'
              description='Você pode escolher entre diferentes valores de doação, únicas ou mensais. Doações únicas acima de R$300 podem ser parceladas em até 5x no cartão de crédito.'
              pathname='/doacao'
              buttonLabel='Quero fazer uma doação'
            />
            {/* <p className='center-label'>ou</p>
              <ChoiceItem
                title='Seja um Construtor'
                description='Você poderá escolher precisamente com qual item da nossa construção gostaria de contribuir e se tornará um Benfeitor Construtor.'
                pathname='construtor/selecionar-local'
                buttonLabel='Quero ser um construtor'
            /> */}
          </ChoiceContainer>
        </Main>
      </Layout>
    </React.Fragment>
  )
}

export default DonationChoice
