import { useAtom } from 'jotai'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import api from '../../services/api/api'
import { donationData } from '../../services/state/donationPurchaseData'
import { formatMonetary } from '../../services/utils/transforms'
import mixins from '../../styles/mixins'
import theme from '../../styles/theme'
import GlobalButton from '../common/GlobalButton'

const { colors } = theme

const Main = styled.div`
  ${mixins.rewardDonationCardAnimation};
  ${mixins.sidePaneCards};
  .card-title {
    text-align: center;
    font-size: 28px;
    color: ${colors.primary};
    margin-bottom: 28px;
  }
  .card-description {
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    color: ${colors.black};
    margin: 16px 0;
  }
  .rewards-title {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: ${colors.black};
  }
  .rewards-itens {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    color: ${colors.gray};
  }
`

const DonationAndRewardsCard = ({
  donationDescription,
  donationValue,
  donationRewards,
  hasDonationRewards,
  donationId,
}) => {
  const history = useHistory()
  const [donationPurchaseData, setDonationPurchaseData] = useAtom(donationData)

  const onDonationItemClick = function () {
    if (donationValue > 0) {
      api
        .get(`regular-donation/${donationId}`)
        .then(res => {
          setDonationPurchaseData({
            ...donationPurchaseData,
            id: donationId,
            value: donationValue,
            isBuilderDonation: false,
            hasRewards: res.data.has_donation_rewards,
            rewards: res.data.donation_rewards,
          })
          history.push(`/pagamento?active-step=payment-method`)
        })
        .catch(() => {})
    } else {
      history.push(`/doacao`)
    }
  }

  return (
    <Main onClick={onDonationItemClick}>
      <h3 className='card-title'>
        {donationValue > 0 ? `R$ ${formatMonetary(donationValue, 0, '', '.')}` : 'Outro valor'}
      </h3>
      {hasDonationRewards ? (
        <React.Fragment>
          <p className='rewards-title'>Recompensas:</p>
          <p className='rewards-itens'>
            {donationRewards.map(rewardItem => (
              <span key={`reward_id_${donationRewards.indexOf(rewardItem)}`}>{rewardItem.reward}</span>
            ))}
          </p>
        </React.Fragment>
      ) : null}
      <p className='card-description'>{donationDescription}</p>
      <GlobalButton
        large={false}
        background={colors.tertiary}
        labelColor={colors.light}
        border={colors.tertiary}
        hoverColor={colors.tertiary}
        buttonLabel='Quero Doar'
      />
    </Main>
  )
}

export default DonationAndRewardsCard
