import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import media from '../../styles/media'
import mixins from '../../styles/mixins'
import theme from '../../styles/theme'

const { colors } = theme

const Main = styled.header`
  background-color: ${colors.light};
  min-height: 225px;
`
const HeroContainer = styled.div`
  ${mixins.sidePadding}
  ${mixins.desktopAlignCenter}
  text-align: center;
  padding-bottom: 60px;
  padding-top: 60px;
  ${media.tablet`
      padding-bottom: 30px;
      padding-top: 30px;
    `}
`
const HeroTitle = styled.h1`
  font-size: 34px;
  font-weight: 700;
  line-height: 38px;
  margin-bottom: 24px;
  color: ${colors.black};
`
const HeroDescription = styled.h2`
  font-size: 16px;
  margin: 0 auto;
  max-width: 600px;
  color: ${colors.gray};
`

const Hero = ({ heroTitle, heroDescription, background, cycleDescription = false }) => {
  const heroDescriptionList = useMemo(() => {
    if (process.env.REACT_APP_BRAND_HERO_DESCRIPTION) {
      return process.env.REACT_APP_BRAND_HERO_DESCRIPTION.split('|')
    } else {
      return [
        'Seja bem-vindo ao Cantinho do Benfeitor Mensageiro',
        'Aqui, sua doação se torna evangelização!',
        'Torne-se um benfeitor mensageiro e juntos levaremos a mensagem do Espírito!',
      ]
    }
  }, [])

  const [heroDescriptionIndex, setHeroDescriptionIndex] = useState(0)

  const cycleTimeoutRef = useRef(null)

  useEffect(() => {
    if (cycleDescription) {
      if (!cycleTimeoutRef.current) {
        cycleTimeoutRef.current = setInterval(() => {
          if (heroDescriptionIndex + 1 >= heroDescriptionList.length) {
            setHeroDescriptionIndex(0)
          } else {
            setHeroDescriptionIndex(heroDescriptionIndex + 1)
          }
        }, 3000)
      }
    }

    return () => {
      if (cycleTimeoutRef.current) {
        clearInterval(cycleTimeoutRef.current)
        cycleTimeoutRef.current = null
      }
    }
  }, [heroDescriptionList, cycleDescription, heroDescriptionIndex])

  return (
    <Main hasBackground={background}>
      <HeroContainer>
        <HeroTitle>{heroTitle}</HeroTitle>
        <HeroDescription>
          {cycleDescription ? heroDescriptionList[heroDescriptionIndex] : heroDescription}
        </HeroDescription>
      </HeroContainer>
    </Main>
  )
}

export default Hero
