import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import api from '../../services/api/api'

const SEO = ({ title, description, ogImage }) => {
  const [siteMetadata, setSiteMetadata] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const metaTitle = title || siteMetadata.site_title
  const metaDescription = description || siteMetadata.site_description
  const metaImage = ogImage || siteMetadata.og_img
  const metaKeywords = siteMetadata.keywords
  const metaCannonical = window.location.href

  useEffect(() => {
    api.get('site-metadata').then(res => {
      setSiteMetadata(res.data)
      setIsLoading(false)
    }).catch(() => {})
  }, [])

  return (
    <React.Fragment>
      {isLoading ? null : (
        <Helmet
          title={metaTitle}
          link={[
            {
              rel: 'canonical',
              href: metaCannonical,
            },
          ]}
          meta={[
            {
              name: `description`,
              content: metaDescription,
            },
            {
              name: 'keywords',
              content: metaKeywords,
            },
            {
              property: `og:title`,
              content: metaTitle,
            },
            {
              property: `og:description`,
              content: metaDescription,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:title`,
              content: metaTitle,
            },
            {
              name: `twitter:description`,
              content: metaDescription,
            },
            {
              property: 'og:image',
              content: metaImage,
            },
            {
              property: 'og:image:width',
              content: 1150,
            },
            {
              property: 'og:image:height',
              content: 600,
            },
            {
              name: 'twitter:card',
              content: `summary_large_image`,
            },
          ]}
        />
      )}
    </React.Fragment>
  )
}

export default SEO
