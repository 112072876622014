import React from 'react'
import styled from 'styled-components'
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'

import GlobalButton from '../common/GlobalButton'
import { Link } from 'react-router-dom'

import theme from '../../styles/theme'
import media from '../../styles/media'

const { colors } = theme

const Main = styled.div`
  margin: 72px 0;
  padding: 36px;
  font-size: 16px;
  font-weight: 400;
  border: solid 1px ${colors.lightShade};
  border-radius: 5px;

  .header-update {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    span {
      padding-right: 16px;
      font-size: 14px;
      color: ${colors.gray};
    }
  }
`
const UpdateTitle = styled.h3`
  font-size: 34px;
  color: ${colors.black};
  padding-bottom: 36px;
  ${media.tablet` font-size: 28px; `}
`
const UpdateDescription = styled.p`
  font-size: 16px;
  color: ${colors.gray};
  padding-bottom: 36px;
`

const ProgressPostItems = ({
  updateNumber,
  updateDate,
  updateTitle,
  updateExcerpt,
  updateId,
}) => {
  const formatDate = publishedDate => {
    const firstDate = parseISO(publishedDate)
    return format(firstDate, "dd 'de' MMMM' de 'yyyy'", { locale: ptBR })
  }

  return (
    <Main>
      <div className='header-update'>
        <span>ATUALIZAÇÃO #{updateNumber}</span>
        <span>{formatDate(updateDate)}</span>
      </div>
      <UpdateTitle>{updateTitle}</UpdateTitle>
      <UpdateDescription>{updateExcerpt}</UpdateDescription>

      <div style={{ maxWidth: '250px' }}>
        <Link to={`/progresso/posts/${updateId}`}>
          <GlobalButton
            large={false}
            dropShadow={false}
            background={colors.light}
            labelColor={colors.primary}
            border={colors.primary}
            hoverColor={colors.lightShade}
            buttonLabel='Leia Mais'
          />
        </Link>
      </div>
    </Main>
  )
}

export default ProgressPostItems
