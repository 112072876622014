import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import CookieConsentContainer from './components/common/CookieConsent'
import FabOverlay from './components/common/FabOverlay'
import Hero from './components/common/Hero'
import Layout from './components/common/Layout'
import MainPageContent from './components/common/MainPageContent'
import HomeTabs from './components/home/HomeTabs'
import GuestSignupPage from './pages/authentication/GuestSignupPage'
import LoginPage from './pages/authentication/LoginPage'
import RecoverPassword from './pages/authentication/RecoverPassword'
import SignupPage from './pages/authentication/SignupPage'
import UserProfile from './pages/authentication/UserProfile'
import BuilderPage from './pages/builder/BuilderPage'
import DonationChoice from './pages/donation/DonationChoice'
import DonationPage from './pages/donation/DonationPage'
import AboutTab from './pages/home/AboutTab'
import BuilderTab from './pages/home/BuilderTab'
import FaqTab from './pages/home/FaqTab'
import ProgressIndividualPost from './pages/home/ProgressIndividualPost'
import ProgressTab from './pages/home/ProgressTab'
import PaymentPage from './pages/payment/Payment'
import ReturnPayment from './pages/payment/ReturnPayment'
import SeriesListeningPage from './pages/series/SeriesListingPage'
import SeriesPage from './pages/series/SeriesPage'
import TermsOfPrivacy from './pages/termsOfPrivacy/TermsOfPrivacy'
import ScrollToTop from './services/utils/scrollHandler'

const Routes = () => {
  const HomeRoutes = () => {
    return (
      <React.Fragment>
        <Layout>
          <Hero
            background={true}
            heroTitle={process.env.REACT_APP_BRAND_HERO_TITLE || 'Cantinho do Benfeitor Mensageiro'}
            cycleDescription={true}
          />
          <MainPageContent />
          <HomeTabs />

          <div id='home-tabs-container'>
            <Switch>
              {/* Redirecting homepage straight to /sobre to make sure the tab will receive the
              active-link class */}
              <Route exact path='/' component={AboutTab} />
              <Route path='/sobre' component={AboutTab} />
              <Route path='/faq' component={FaqTab} />
              <Route path='/progresso' exact component={ProgressTab} />
              <Route path='/progresso/page/:pageId' component={ProgressTab} />
              <Route path='/progresso/posts/:postId' component={ProgressIndividualPost} />
              <Route path='/seja-um-construtor' component={BuilderTab} />
            </Switch>
          </div>
          <CookieConsentContainer />
        </Layout>
      </React.Fragment>
    )
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <FabOverlay />
      <Switch>
        <Route path='/login' component={LoginPage} />
        <Route path='/signup' component={SignupPage} />
        <Route path='/guest-login' component={GuestSignupPage} />
        <Route path='/recuperar-senha' component={RecoverPassword} />
        <Route path='/editar-perfil' component={UserProfile} />
        <Route path='/escolher-doacao' component={DonationChoice} />
        <Route path='/doacao' component={DonationPage} />
        <Route path='/pagamento' component={PaymentPage} />
        <Route path='/payment-return' component={ReturnPayment} />
        <Route path='/termos-de-privacidade' component={TermsOfPrivacy} />
        <Route path='/construtor/:mapId' component={BuilderPage} />
        <Route path='/caminhos/:seriesId' component={SeriesPage} />
        <Route path='/caminhos' exact component={SeriesListeningPage} />
        <Route component={HomeRoutes} />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
