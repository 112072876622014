import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import oracaoPlayLogo from '../../assets/imgs/Doacao_Logo.png'
import jovensSaradosLogo from '../../assets/imgs/js-black.png'
import { default as media, default as mixins } from '../../styles/media'
import theme from '../../styles/theme'

const { colors } = theme

const Main = styled.footer`
  display: flex;
  align-items: center;
  background: ${colors.light};
  min-height: 70px;
  margin: 48px 0 0 0;
  box-shadow: 0 2px 1.75px rgba(0, 0, 0, 0.25);
  z-index: 15;
`
const Nav = styled.nav`
  ${mixins.sidePadding};
  margin: 0 auto;
  max-width: 1600px;
`

const GridContainer = styled.div`
  ${media.tablet`padding: 8px 0px;`}
  padding: 8px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .logo-container {
    padding-left: 16px;
    display: flex;
    align-items: center;
  }
  .logo-img {
    width: auto;
    height: 40px;
  }
  ul {
    display: flex;
    padding-left: 56px;
    ${media.tinyDesktop`
      display: block;
      padding-left: 12px;
    `}
  }
  li {
    margin: 16px;
  }
  a {
    color: ${colors.primary};
    font-size: 16px;
    ${media.thone`font-size: 14px;`}
  }
`
const LogoText = styled.p`
  font-size: 18px;
  color: ${colors.secondary};
  font-weight: 700;
  padding-left: 4px;
  letter-spacing: -1px;
`

export default function NavFooter() {
  return (
    <Main>
      <Nav>
        <GridContainer>
          <Link to='/'>
            <div className='logo-container'>
              <img
                className='logo-img'
                src={process.env.REACT_APP_BRAND_ALTERNATE_LOGO === '1' ? jovensSaradosLogo : oracaoPlayLogo}
                alt={`Logo ${process.env.REACT_APP_BRAND_NAME_SHORT}`}
              />
              <LogoText>{process.env.REACT_APP_BRAND_NAME}</LogoText>
            </div>
          </Link>
          <ul>
            <li>
              <Link to='/'>Sobre</Link>
            </li>
            <li>
              <Link to='/faq'>FAQ</Link>
            </li>
            {/* <li><Link to='/progresso/page/1'>Progresso</Link></li> */}
            {/* <li><Link to='/seja-um-construtor'>Seja um Construtor</Link></li> */}
            <li>
              <Link to='/termos-de-privacidade'>Política de Privacidade</Link>
            </li>
          </ul>
        </GridContainer>
      </Nav>
    </Main>
  )
}
