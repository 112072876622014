
export const validateName = (value) => {
  let error

  let nome = value.trim()
  if (!value) {
    error = 'Insira o seu nome completo'
  } else if (!/^[A-Za-zÀ-ÖØ-öø-ÿ .'-]+$/.test(nome)) {
    error = 'Insira um nome válido'
  } else if (nome.split(' ').length < 2) {
    error = 'Insira o seu nome completo'
  } else {
    error = false
  }
  return error
}
