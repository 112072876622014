import React, { useState } from 'react'
import styled from 'styled-components'

import theme from '../../styles/theme'
import {FiMenu} from 'react-icons/fi'

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Link } from 'react-router-dom'

const {colors} = theme

const StyledDropdown = styled(Dropdown)`
  button {
    font-size: 14px;
    color: ${colors.primary};
    background-color: ${colors.background};
    border-color: ${colors.background};
    :hover,
    :focus {
      color: ${colors.primary};
      background-color: ${colors.light};
      border-color: ${colors.light};
    }
  }
  a {
    color: ${colors.primary};
  }
  .menu-icon {
    width: 24px;
    height: 24px;
  }
`

const MenuBadge = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => setDropdownOpen(prevState => !prevState)

  return (
    <StyledDropdown direction='down' isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle>
        <FiMenu className='menu-icon'/>
      </DropdownToggle>
      <DropdownMenu right>
        <React.Fragment>
          <DropdownItem>
            <Link to='/signup'>
              Criar Conta
            </Link>
          </DropdownItem>
          <DropdownItem>
            <Link to='/login'>
              Entrar
            </Link>
          </DropdownItem>
        </React.Fragment>
      </DropdownMenu>
    </StyledDropdown>
  )
}

export default MenuBadge