import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import mixins from '../../styles/mixins'
import theme from '../../styles/theme'
import GlobalButton from '../common/GlobalButton'

const { colors } = theme

const Main = styled.div`
  ${mixins.rewardDonationCardAnimation};
  ${mixins.sidePaneCards};
  .card-title {
    text-align: center;
    font-size: 28px;
    color: ${colors.primary};
    margin-bottom: 28px;
  }
  .card-description {
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    color: ${colors.black};
    margin: 16px 0;
  }
  .rewards-title {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: ${colors.black};
  }
  .rewards-itens {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    color: ${colors.gray};
  }
`

const SeriesCard = ({ seriesDescription, seriesTitle, seriesId }) => {
  const history = useHistory()

  const onSeriesItemClick = () => {
    if (seriesId) {
      history.push(`/caminhos/${seriesId}`)
    }
  }

  return (
    <Main onClick={seriesId ? onSeriesItemClick : null}>
      {seriesTitle && <h3 className='card-title'>{seriesTitle}</h3>}
      {seriesDescription && <p className='card-description'>{seriesDescription}</p>}
      {seriesId ? (
        <GlobalButton
          large={false}
          background={colors.tertiary}
          labelColor={colors.light}
          border={colors.tertiary}
          hoverColor={colors.tertiary}
          buttonLabel='Trilhar caminho'
        />
      ) : null}
    </Main>
  )
}

export default SeriesCard
