import React from 'react'
import styled from 'styled-components'

import {FaWhatsapp} from 'react-icons/fa'

import theme from '../../styles/theme'
const { colors } = theme

const Main = styled.button `
  display: inline-flex;
  position: fixed;
  bottom: 18px;
  right: 18px;
  background-color: ${colors.primary};
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 
  0 6px 10px 0px rgb(0 0 0 / 14%), 
  0 1px 18px 0px rgb(0 0 0 / 12%);
  transition: 120ms ease-in-out;
  
  :hover {
    background: ${colors.primaryShade};
  }
`

const Icon = styled(FaWhatsapp)`
  color: ${colors.primaryContrast};
  width: 24px;
  height: 24px;
`

const FabButton = ({onClick}) => {
  return (
    <Main onClick={onClick}>
      <Icon />
    </Main>
  )
}

export default FabButton