import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import BuilderDonationItem from './BuilderDonationItem'
import { Spinner } from 'reactstrap'
import api from '../../services/api/api'

const Main = styled.div`
  max-width: 1100px;
`

const BuilderDonationItemContainer = () => {
  const [builderDonationList, setBuilderDonationList] = useState([])
  const [isLoading, setIsLoading] = useState([])

  useEffect(() => {
    api.get('builder-donation').then(res => {
      setTimeout(() => {
        setBuilderDonationList(res.data)
        setIsLoading(false)
      }, 100)
    }).catch(() => {})
  }, [])

  return (
    <React.Fragment>
      {isLoading ? (
        <div style={{ textAlign: 'center', margin: '36px', height: '100vh' }}>
          <Spinner color='secondary' />
        </div>
      ) : builderDonationList ? (
        <Main>
          {builderDonationList.map(donationItem => (
            <BuilderDonationItem
              itemTitle={donationItem.donation_title}
              itemDescription={donationItem.donation_description}
              itemDonationAmount={donationItem.donation_amount}
              itemDonationPercentage={donationItem.donation_percentage}
              itemImage={donationItem.donation_img}
              itemImageAlt={donationItem.donation_img_alt}
              itemDonationId={donationItem.id}
              key={donationItem.id}
            />
          ))}
        </Main>
      ) : null}
    </React.Fragment>
  )
}

export default BuilderDonationItemContainer
