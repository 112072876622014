import React from 'react'
import styled from 'styled-components'

import SEO from '../../components/common/Seo'

import theme from '../../styles/theme'
import mixins from '../../styles/mixins'

import BuilderDonationItemContainer from '../../components/home/BuilderDonationItemContainer'

const { colors } = theme

const Main = styled.div`
  ${mixins.sidePadding}
  ${mixins.desktopAlignCenter}
  padding-top: 48px;  
  font-size: 18px;
  font-weight: 700;
  color: ${colors.primary};
`

const BuilderTab = () => {
  return (
    <React.Fragment>
      <SEO />
      <Main>
        <BuilderDonationItemContainer />
      </Main>
    </React.Fragment> 
  )
}

export default BuilderTab
