import React from 'react'
import styled from 'styled-components'
import { Progress } from 'reactstrap'
import { Link } from 'react-router-dom'

import theme from '../../styles/theme'
import media from '../../styles/media'

import GlobalButton from '../common/GlobalButton'

const { colors } = theme

const Main = styled.div `
  display: flex;
  align-items: center;
  margin: 64px 0;
  width: 100%;
  border-radius: 5px;
  border: solid 2px ${colors.lightShade};
  padding: 26px;
  ${media.tinyDesktop` display: block; `}
  
  .progress-bar {
    background-color: ${colors.secondary};
  }
  .img-container {
    padding-right: 32px;
    ${media.tinyDesktop` padding: 0; `}
  }
  .content-container {
    width: 100%;
  }
`
const ItemImage = styled.img `
  width: 450px;
  height: 300px;
  object-fit: cover;
  border-radius: 5px;
  ${media.bigDesktop`
    width: 35vw;
    height: 300px;
  `}
  ${media.tinyDesktop`
    width: 100%;
    height: 350px;
  `}
  ${media.phablet`
    width: 100%;
    height: 250px;
  `}
`
const CardTitle = styled.h3 `
  color: ${colors.primary};
  ${media.tinyDesktop` padding-top: 16px; `}
  font-size: 20px;
`
const CardDescription = styled.p `
  font-size: 16px;
  font-weight: 400;
  color: ${colors.gray};
  margin: 16px 0;
`
const DonationRepresentativePercentage = styled.p `
  color: ${colors.gray};
  font-size: 18px;
  font-weight: 400;
  margin: 6px 0 32px 0;
`

const BuilderDonationItem = ({
  itemTitle,
  itemDescription,
  itemDonationPercentage,
  itemImage,
  itemImageAlt,
  itemDonationId
}) => {
  return(
    <Main>
      <div className="img-container">
        <ItemImage src={itemImage ? itemImage :
          'https://images.unsplash.com/photo-1574642631319-ee4e726c5888?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NTd8fGNodXJjaCUyMGludGVyaW9yfGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60'
        } alt={itemImageAlt} />
      </div>
      <div className="content-container">
        <CardTitle>{itemTitle}</CardTitle>
        <CardDescription>{itemDescription}</CardDescription>
        <Progress value={itemDonationPercentage} />
        
        { itemDonationId === 'selecionar-local' ? (
          <DonationRepresentativePercentage>{itemDonationPercentage}% já construído</DonationRepresentativePercentage>
        ): (
          <DonationRepresentativePercentage>{itemDonationPercentage}% desta seção já construído</DonationRepresentativePercentage>
        )}

        <Link to={`/construtor/${itemDonationId}`}>
          <GlobalButton 
            large={false}
            dropShadow={true}
            background={colors.tertiary}
            labelColor={colors.light}
            border={colors.tertiary}
            hoverColor={colors.tertiary}
            buttonLabel="Saiba mais"
          />
        </Link>
      </div>
    </Main>
  )
}

export default BuilderDonationItem
