import React, { useState } from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import InputMask from 'react-input-mask'
import { useAtom } from 'jotai'

import { userData } from '../../services/state/appState'
import { capitalize, formatCelNumber, formatDate } from '../../services/utils/transforms'
import { isUserPermanentlyLogged, loginUser } from '../../services/utils/user'
import { searchAddressByCep } from '../../services/utils/searchAddressByCep'
import legacyApi from '../../services/api/legacyApi'

import GlobalButton from '../common/GlobalButton'
import { Breadcrumb, BreadcrumbItem, Spinner, Col, Container, Row } from 'reactstrap'

import media from '../../styles/media'
import mixins from '../../styles/mixins'
import theme from '../../styles/theme'
import AuthenticationAlert from '../common/AuthenticationAlert'

const { colors } = theme

const StyledForm = styled.form`
  margin-top: 36px;
  ${mixins.formStyles}
  .breadcrumb {
    margin-top: 48px;
    background-color: ${colors.light};
    font-size: 16px;
  }
`
const StyledGrid = styled(Container)`
  ${mixins.mainPageGrid}

  .col {
    ${media.tinyDesktop` padding: 0; `}
  }
  .col-3 {
    ${media.tinyDesktop` padding: 0; `}
  }
  .col-4 {
    ${media.tinyDesktop` padding: 0; `}
  }
  .alert-label {
    font-size: 16px;
  }
`

const UserProfileForm = () => {
  const [cepIsLoading, setCepIsLoading] = useState(false)
  const [atomUserData, setAtomUserData] = useAtom(userData)
  const [updatingUser, setUpdatingUser] = useState(false)
  const [updateUserFinished, setUpdateUserFinished] = useState(false)
  const [updateUserSuccess, setUpdateUserSuccess] = useState(false)

  const cepSearchHandler = (data, setFieldValue) => {
    setFieldValue('cidade', data.cityName)
    setFieldValue('bairro', data.neighborhood)
    setFieldValue('endereco', data.streetName)
    setFieldValue('estado', data.stateName)

    // Fields that are not updated by the CEP will be wiped clean
    setFieldValue('numero', '')
    setFieldValue('referencia', '')
    setFieldValue('complemento', '')
  }

  return (
    <Formik
      initialValues={{
        nome: atomUserData.nome ? atomUserData.nome : '',
        email: atomUserData.email ? atomUserData.email.toLowerCase() : '',
        senhapass: '',
        celular: atomUserData.celular ? atomUserData.celular : '',
        data_nascimento: atomUserData.data_nascimento_f ? atomUserData.data_nascimento_f : '',
        endereco: atomUserData.endereco ? atomUserData.endereco : '',
        cep: atomUserData.cep ? atomUserData.cep : '',
        numero: atomUserData.endereco_numero ? atomUserData.endereco_numero : '',
        complemento: atomUserData.endereco_complemento ? atomUserData.endereco_complemento : '',
        bairro: atomUserData.endereco_bairro ? atomUserData.endereco_bairro : '',
        referencia: '',
        cidade: atomUserData.cidade_nome ? atomUserData.cidade_nome : '',
        estado: atomUserData.estado_nome ? atomUserData.estado_nome : '',
      }}
      validationSchema={Yup.object().shape({
        // User data
        nome: Yup.string().required('Este campo não pode ficar vazio, insira um nome válido.'),

        email: Yup.string()
          .required('Este campo não pode ficar vazio, insira um email válido.')
          .email('Insira um email válido'),

        senhapass: Yup.string().matches(
          /^(?=.*[a-zA-Z]).{6,}$/,
          'Deve ter ao menos 6 caracteres entre letras e números'
        ),

        celular: Yup.string()
          .required('Este campo não pode ficar vazio, insira um número de celular válido.')
          .matches(/^\(?0?[1-9]{2}\)?[- ]?9[0-9]{4}[- ]?[0-9]{4}$/, 'Insira um número de celular válido'),

        data_nascimento: Yup.string()
          .required('Este campo não pode ficar vazio, insira uma data válida.')
          .matches(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/, 'Insira uma data válida'),

        // Address data, not required by validation here.
        endereco: Yup.string(),
        cep: Yup.string(),
        numero: Yup.string(),
        complemento: Yup.string(),
        bairro: Yup.string(),
        cidade: Yup.string(),
        estado: Yup.string(),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        const submitUserData = async () => {
          setUpdatingUser(true)
          return legacyApi
            .post('', {
              nome: capitalize(values.nome),
              email: capitalize(values.email),
              senhapass: values.senhapass,
              celular: formatCelNumber(values.celular),
              data_nascimento: formatDate(values.data_nascimento),
              cep: values.cep,
              endereco: values.endereco,
              endereco_numero: values.numero,
              endereco_complemento: values.complemento,
              endereco_bairro: values.bairro,
              cidadeNome: values.cidade,
              estadoNome: values.estado,
              cmesToken: atomUserData.token,
              op: 'benfeitorUpdate',
            })
            .then(async response => {
              const data = response.data

              if (data.sucess) {
                const updatedUserData = {
                  ...atomUserData,
                  nome: values.nome,
                  email: values.email,
                  celular: formatCelNumber(values.celular),
                  data_nascimento: formatDate(values.data_nascimento),

                  cep: values.cep ? values.cep : null,
                  endereco: values.endereco ? values.endereco : null,
                  endereco_numero: values.numero ? values.numero : null,
                  endereco_complemento: values.complemento ? values.complemento : null,
                  endereco_bairro: values.bairro ? values.bairro : null,
                  cidade_nome: values.cidade ? values.cidade : null,
                  estado_nome: values.estado ? values.estado : null,
                }

                await setAtomUserData(updatedUserData)
                await loginUser(updatedUserData, isUserPermanentlyLogged())

                setUpdatingUser(false)
                setUpdateUserSuccess(true)
                setUpdateUserFinished(true)

              } else {
                setUpdateUserSuccess(false)
                setUpdateUserFinished(true)

              }
            })
            .catch(() => {
              setUpdateUserSuccess(false)
              setUpdateUserFinished(true)
            })
        }
        await submitUserData()
        setSubmitting(false)
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
        <StyledForm onSubmit={handleSubmit}>
          <StyledGrid>
            <Breadcrumb>
              <BreadcrumbItem>Informações Pessoais:</BreadcrumbItem>
            </Breadcrumb>
            <Row>
              <Col>
                <div className='form-control-container'>
                  <label htmlFor='nome'>Nome Completo</label>
                  <input
                    type='text'
                    name='nome'
                    placeholder='Nome e sobrenome'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.nome}
                  />
                  {errors.nome && touched.nome && <div className='validation-error'>{errors.nome}</div>}
                </div>
              </Col>
              <Col>
                <div className='form-control-container'>
                  <label htmlFor='email'>Email</label>
                  <input
                    type='email'
                    inputMode='email'
                    autoComplete='email'
                    name='email'
                    placeholder='Endereço de email'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {errors.email && touched.email && <div className='validation-error'>{errors.email}</div>}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='form-control-container'>
                  <label htmlFor='senhapass'>Senha</label>
                  <input
                    type='password'
                    autoComplete='new-password'
                    name='senhapass'
                    placeholder='Nova senha'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.senhapass}
                  />
                  {errors.senhapass && touched.senhapass && <div className='validation-error'>{errors.senhapass}</div>}
                </div>
              </Col>
              <Col>
                <div className='form-control-container'>
                  <label htmlFor='celular'>Número de telefone</label>
                  <InputMask
                    type='tel'
                    name='celular'
                    placeholder='Número de telefone'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.celular}
                    mask='(99)99999-9999'
                  />
                  {errors.celular && touched.celular && <div className='validation-error'>{errors.celular}</div>}
                </div>
              </Col>
              <Col>
                <div className='form-control-container'>
                  <label htmlFor='data_nascimento'>Data de nascimento</label>
                  <InputMask
                    type='tel'
                    name='data_nascimento'
                    placeholder='Data de nascimento'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.data_nascimento}
                    mask='99/99/9999'
                  />
                  {errors.data_nascimento && touched.data_nascimento && (
                    <div className='validation-error'>{errors.data_nascimento}</div>
                  )}
                </div>
              </Col>
            </Row>
          </StyledGrid>
          <StyledGrid fluid>
            <Breadcrumb>
              <BreadcrumbItem>Endereço:</BreadcrumbItem>
            </Breadcrumb>
            <Row>
              <Col xs='3'>
                <div className='form-control-container'>
                  <label htmlFor='cep'>CEP</label>
                  <InputMask
                    type='tel'
                    autoComplete='postal-code'
                    name='cep'
                    placeholder='_____-___'
                    onChange={handleChange}
                    onBlur={e => {
                      handleBlur(e)
                      let cepValue = e.currentTarget.value
                      setCepIsLoading(true)
                      searchAddressByCep(cepValue).then(data => {
                        if (data) {
                          cepSearchHandler(data, setFieldValue)
                          setCepIsLoading(false)
                        } else {
                          setCepIsLoading(false)
                        }
                      })
                    }}
                    value={values.cep}
                    mask='99999-999'
                  />
                  {errors.cep && touched.cep && <div className='validation-error'>{errors.cep}</div>}
                </div>
              </Col>
              <Col>
                <div className='form-control-container'>
                  <label htmlFor='endereco'>Endereço</label>
                  <input
                    type='text'
                    inputMode='text'
                    autoComplete='street-address'
                    name='endereco'
                    placeholder='ex: Rua Brigadeiro Faria Lima'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.endereco}
                    disabled={cepIsLoading}
                  />
                  {errors.endereco && touched.endereco && <div className='validation-error'>{errors.endereco}</div>}
                </div>
              </Col>
              <Col xs='3'>
                <div className='form-control-container'>
                  <label htmlFor='numero'>Número</label>
                  <input
                    inputMode='tel'
                    autoComplete='off'
                    name='numero'
                    placeholder='ex: 1541'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.numero}
                    disabled={cepIsLoading}
                  />
                  {errors.numero && touched.numero && <div className='validation-error'>{errors.numero}</div>}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='form-control-container'>
                  <label htmlFor='complemento'>Complemento (opcional)</label>
                  <input
                    type='text'
                    inputMode='text'
                    autoComplete='off'
                    name='complemento'
                    placeholder='Ex: Bloco X, apt 204'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.complemento}
                    disabled={cepIsLoading}
                  />
                  {errors.complemento && touched.complemento && (
                    <div className='validation-error'>{errors.complemento}</div>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs='4'>
                <div className='form-control-container'>
                  <label htmlFor='bairro'>Bairro</label>
                  <input
                    type='text'
                    inputMode='text'
                    autoComplete='off'
                    name='bairro'
                    placeholder='Ex: Jardim Esmeralda'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.bairro}
                    disabled={cepIsLoading}
                  />
                  {errors.bairro && touched.bairro && <div className='validation-error'>{errors.bairro}</div>}
                </div>
              </Col>
              <Col>
                <div className='form-control-container'>
                  <label htmlFor='cidade'>Cidade</label>
                  <input
                    type='tel'
                    autoComplete='off'
                    name='cidade'
                    placeholder='Preencha o CEP'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.cidade}
                    disabled={true}
                  />
                  {errors.cidade && touched.cidade && <div className='validation-error'>{errors.cidade}</div>}
                </div>
              </Col>
              <Col>
                <div className='form-control-container'>
                  <label htmlFor='estado'>Estado</label>
                  <input
                    type='text'
                    inputMode='text'
                    autoComplete='off'
                    name='estado'
                    placeholder='Preencha o CEP'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.estado}
                    disabled={true}
                  />
                  {errors.estado && touched.estado && <div className='validation-error'>{errors.estado}</div>}
                </div>
              </Col>
            </Row>

            {updateUserFinished && updateUserSuccess ? (
              <AuthenticationAlert
                alertHighlight={colors.successHighlight}
                alertBackground={colors.successBackground}
                alertIconBackground={colors.background}
                alertWarning={true}
              >
                <p className='alert-label'>
                  Suas configurações foram salvas com sucesso.
                </p>
              </AuthenticationAlert>
            ) : null }

            {updateUserFinished && !updateUserSuccess ? (
              <AuthenticationAlert
                alertHighlight={colors.warningHighlight}
                alertBackground={colors.warningBackground}
                alertIconBackground={colors.background}
                alertWarning={true}
              >
                <p className='alert-label'>
                  Ocorreu um erro ao salvar suas configurações. Por favor, tente novamente mais tarde.
                </p>
              </AuthenticationAlert>
            ) : null }


          </StyledGrid>

          <GlobalButton
            large={false}
            dropShadow={true}
            background={colors.primary}
            labelColor={colors.light}
            border={colors.primary}
            hoverColor={colors.primary}
            buttonLabel={updatingUser ? <Spinner /> : 'Salvar configurações'}
            disabled={updatingUser}
            disabledColor={colors.primaryTint}
            type='submit'
          />
        </StyledForm>
      )}
    </Formik>
  )
}

export default UserProfileForm
