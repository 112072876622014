import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import styled from 'styled-components'
import media from '../../styles/media'
import mixins from '../../styles/mixins'
import theme from '../../styles/theme'
import GlobalButton from '../common/GlobalButton'

const { colors } = theme
const NavItem = styled.div`
  display: inline-block;
`
const Nav = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
`
const TabsWrapper = styled.nav`
  font-size: 18px;
  display: flex;
  justify-content: space-evenly;
  position: sticky;
  z-index: 2;
  top: 0;

  background: ${colors.background};
  border-top: 2px solid ${colors.lightShade};
  border-bottom: 2px solid ${colors.lightShade};

  ${media.phablet`
    font-size: 14px;
  `}

  .tabs-button-container {
    width: 200px;
    align-items: center;
    display: flex;
    ${media.tinyDesktop`
      display: none;
    `}
  }

  ${mixins.tabStyleOverride}
`

const HomeTabs = () => {
  return (
    <React.Fragment>
      <TabsWrapper>
        <Nav className='nav-tabs'>
          <NavItem>
            <NavLink
              isActive={(match, location) => location.pathname === '/' || location.pathname === '/sobre'}
              to='/sobre'
              className='nav-link'
              activeClassName='active nav-link-active'
            >
              Sobre Nós
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to='/faq' className='nav-link' activeClassName='active nav-link-active'>
              Perguntas Frequentes
            </NavLink>
          </NavItem>

          {process.env.REACT_APP_YOUTUBE ? (
            <NavItem>
              <a href={process.env.REACT_APP_YOUTUBE} rel='noreferrer' target='_blank' className='nav-link'>
                Youtube
              </a>
            </NavItem>
          ) : null}

          {process.env.REACT_APP_INSTAGRAM ? (
            <NavItem>
              <a href={process.env.REACT_APP_INSTAGRAM} rel='noreferrer' target='_blank' className='nav-link'>
                Instagram
              </a>
            </NavItem>
          ) : null}

          {process.env.REACT_APP_TELEGRAM ? (
            <NavItem>
              <a href={process.env.REACT_APP_TELEGRAM} rel='noreferrer' target='_blank' className='nav-link'>
                Telegram
              </a>
            </NavItem>
          ) : null}

          <NavItem>
            <NavLink to='/caminhos' className='nav-link' activeClassName='active nav-link-active'>
              Trilha de Oração
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink to='/progresso' className='nav-link' activeClassName='active nav-link-active'>
              Progresso
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to='/seja-um-construtor' className='nav-link' activeClassName='active nav-link'>
              Seja um doador
            </NavLink>
          </NavItem>  */}
        </Nav>

        <div className='tabs-button-container'>
          <Link to='/doacao' style={{ width: '100%' }}>
            <GlobalButton
              dropShadow={true}
              background={colors.tertiary}
              labelColor={colors.light}
              border={colors.tertiary}
              hoverColor={colors.tertiary}
              buttonLabel='QUERO DOAR'
            />
          </Link>
        </div>
      </TabsWrapper>
    </React.Fragment>
  )
}

export default HomeTabs
