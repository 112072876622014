import React, { useEffect, useState } from 'react'

import { Spinner } from 'reactstrap'
import { SidebarTitle } from '../../pages/home/AboutTab'
import legacyApi from '../../services/api/legacyApi'
import SeriesCard from './SeriesCard'

const SeriesPane = () => {
  const [seriesListing, setSeriesListing] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    legacyApi
      .post('', {
        op: 'seriesGetListing',
      })
      .then(res => {
        setSeriesListing(res.data)
        setIsLoading(false)
      })
      .catch(() => {})
  }, [])

  return isLoading || (seriesListing && seriesListing.length > 0) ? (
    <aside>
      <SidebarTitle>Trilhe um caminho de oração:</SidebarTitle>

      {isLoading ? (
        <div style={{ textAlign: 'center', margin: '36px', height: '25vh' }}>
          <Spinner color='secondary' />
        </div>
      ) : seriesListing && seriesListing.length > 0 ? (
        seriesListing.map(item => (
          <SeriesCard seriesTitle={item.series_title} seriesId={item.series_id} key={item.series_id} />
        ))
      ) : (
        <SeriesCard seriesDescription={'No momento não temos nenhum caminho de oração. Tente novamente outra hora'} />
      )}
    </aside>
  ) : null
}

export default SeriesPane
