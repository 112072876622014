import React, { useState} from 'react'
import styled from 'styled-components'
import { useAtom } from 'jotai'
import { Formik } from 'formik'
import CpfCnpj from '@react-br-forms/cpf-cnpj-mask'
import * as Yup from 'yup'

import api from '../../services/api/api'
import { guestLogin, userData } from '../../services/state/appState'
import { formatCpf } from '../../services/utils/transforms'
import { loginUser } from '../../services/utils/user'

import mixins from '../../styles/mixins'
import theme from '../../styles/theme'

import GlobalButton from '../common/GlobalButton'
import { useHistory } from 'react-router-dom'
import AuthenticationAlert from '../common/AuthenticationAlert'
import FormikErrorFocus from 'formik-error-focus'

const { colors } = theme

const StyledForm = styled.form`
  ${mixins.formStyles}
  .form-control-container {
    margin: 12px 0 !important;
  }
  .alert-label {
    font-size: 16px;
  }
`

const LoginEmailCheck = () => {
  const history = useHistory()

  const [loginRequestError, setLoginRequestError] = useState(false)
  const [emptyFormSent, setUserEmptyFormSent] = useState(false)

  const [, setAtomUserData] = useAtom(userData)
  const [, setGuestLoginAtom] = useAtom(guestLogin)

  return (
    <Formik
      initialValues={{
        login: '',
        cpf: '',
      }}
      validationSchema={Yup.object().shape({
        login: Yup.string().email('Insira um endereço de email válido').required('Insira seu endereço de email'),
        cpf: Yup.string().required('Insira seu CPF'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        if (!values.login && !values.cpf) {
          setUserEmptyFormSent(true)
        }

        if (values.login || values.cpf) {
          if (emptyFormSent === true) {
            setUserEmptyFormSent(false)
          }

          const formLogin = values.login ? values.login : null
          const formCpf = values.cpf ? values.cpf : null

          api.get(`search/benfeitor?mail=${formLogin}&cpf=${formatCpf(formCpf)}`)
            .then(async response => {
              if (response.data.resultSession) {
                await loginUser(response.data.resultSession, false)
                await setAtomUserData(response.data.resultSession)
                await history.push('/pagamento?active-step=payment-method')
              }
              else if (response.data.resultEmailFound) {
                await setGuestLoginAtom(response.data.resultEmailFound)
                await history.push('/guest-login?active-step=password-check&from=user-check')
              }
              else if (response.data.error === true) {
                setLoginRequestError(true)
              }
              else {
                history.push(`/signup?from=guest&mail=${formLogin}&cpf=${formCpf}`)
              }

            }).catch(() => {
              setLoginRequestError(true)
          })
        }

        setSubmitting(false)
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
        <StyledForm onSubmit={handleSubmit}>
          {loginRequestError ? (
            <AuthenticationAlert
              alertHighlight={colors.warningHighlight}
              alertBackground={colors.warningBackground}
              alertWarning={true}
            >
              <p className='alert-label'>
                Houve um erro ao processar o seu pedido. Por favor, tente novamente mais tarde.
              </p>
            </AuthenticationAlert>
          ) : null}

          {emptyFormSent ? (
            <AuthenticationAlert
              alertHighlight={colors.warningHighlight}
              alertBackground={colors.warningBackground}
              alertWarning={true}
            >
              <p className='alert-label'>
                Precisamos que preencha as informações abaixo para que possamos prosseguir com a doação.
              </p>
            </AuthenticationAlert>
          ) : null}

          <div className='form-control-container'>
            <input
              type='email'
              inputMode='email'
              autoComplete='email'
              name='login'
              placeholder='Email'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.login}
            />
            {errors.login && touched.login && <div className='validation-error'>{errors.login}</div>}
          </div>
          <div className='form-control-container'>
            <CpfCnpj
              type='text'
              maxLength='14'
              inputMode='numeric'
              name='cpf'
              placeholder='CPF'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.cpf}
            />
            {errors.cpf && touched.cpf && <div className='validation-error'>{errors.cpf}</div>}
          </div>

          <GlobalButton
            large={false}
            dropShadow={true}
            background={colors.primary}
            labelColor={colors.light}
            border={colors.primary}
            hoverColor={colors.primary}
            buttonLabel='Prosseguir'
            type='submit'
          />

          <FormikErrorFocus
            offset={0}
            align={'top'}
            focusDelay={50}
            ease={'linear'}
            duration={500}
          />

        </StyledForm>
      )}
    </Formik>
  )
}

export default LoginEmailCheck
