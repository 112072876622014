import { Field, Formik } from 'formik'
import FormikErrorFocus from 'formik-error-focus'
import { useAtom } from 'jotai'
import { default as React, useState } from 'react'
import InputMask from 'react-input-mask'
import { Modal, ModalBody, Spinner } from 'reactstrap'
import styled from 'styled-components'
import * as Yup from 'yup'
import GlobalButton from '../../components/common/GlobalButton'
import api from '../../services/api/api'
import { fabOverlayModal, fabOverlayModalMessage, guestLogin } from '../../services/state/appState'
import { uncapitalize } from '../../services/utils/transforms'
import { getUser } from '../../services/utils/user'
import { validateName } from '../../services/utils/validation'
import mixins from '../../styles/mixins'
import theme from '../../styles/theme'
import AuthenticationAlert from './AuthenticationAlert'
import FabButton from './FabButton'

const { colors } = theme

const Main = styled.div`
  position: fixed;
  z-index: 20;
`
const StyledModalBody = styled(ModalBody)`
  padding: 32px;

  p {
    font-size: 16px;
  }
`
const StyledForm = styled.form`
  ${mixins.formStyles}
`

const FabOverlay = () => {
  const [atomModal, setAtomModal] = useAtom(fabOverlayModal)
  const [atomModalMessage] = useAtom(fabOverlayModalMessage)
  const [guestLoginAtom] = useAtom(guestLogin)

  const [isLoading, setIsLoading] = useState(false)
  const [sentWithSuccess, setSentWithSuccess] = useState(false)
  const [requestError, setRequestError] = useState(false)
  const closeModal = () => setAtomModal(false)

  return (
    <Main>
      <FabButton onClick={() => setAtomModal(true)} />
      <Modal isOpen={atomModal}>
        <StyledModalBody>
          <Formik
            initialValues={{
              question: atomModalMessage ? atomModalMessage : '',
              nome: getUser() ? getUser().nome : '',
              email: getUser() ? uncapitalize(getUser().email) : guestLoginAtom ? guestLoginAtom : '',
              celular: getUser() ? getUser().celular : '',
            }}
            validationSchema={Yup.object().shape({
              question: Yup.string().required('Faça uma pergunta antes de enviar.'),

              celular: Yup.string()
                .required('Preencha este campo')
                .matches(/^\(?0?[1-9]{2}\)?[- ]?9[0-9]{4}[- ]?[0-9]{4}$/, 'Insira um número de celular válido'),

              email: Yup.string().email('Insira um email válido').required('Preencha este campo'),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true)
              api
                .post('help', {
                  ...values,
                })
                .then(response => {
                  setIsLoading(true)
                  if (response.data) {
                    if (requestError) {
                      setRequestError(false)
                    }
                    setIsLoading(false)
                    setSubmitting(false)
                    setSentWithSuccess(true)
                    setTimeout(closeModal, 2000)
                  }
                })
                .catch(() => {
                  setSubmitting(false)
                  setRequestError(true)
                })
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <StyledForm onSubmit={handleSubmit}>
                <p>
                  Tem alguma pergunta sobre a qual gostaria de tirar uma dúvida? Faça sua pergunta aqui e as Irmãs
                  Carmelitas irão lhe responder assim que possível:
                </p>

                <div className='form-control-container'>
                  <textarea name='question' onChange={handleChange} onBlur={handleBlur} value={values.question} />
                  {errors.question && touched.question && <div className='validation-error'>{errors.question}</div>}
                </div>
                <div className='form-control-container'>
                  <Field
                    type='email'
                    inputMode='email'
                    autoComplete='email'
                    name='email'
                    placeholder='Endereço de email'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {errors.email && touched.email && <div className='validation-error'>{errors.email}</div>}
                </div>
                <div className='form-control-container'>
                  <Field
                    type='text'
                    name='nome'
                    placeholder='Nome completo'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    validate={validateName}
                    value={values.nome}
                  />
                  {errors.nome && touched.nome && <div className='validation-error'>{errors.nome}</div>}
                </div>
                <div className='form-control-container'>
                  <InputMask
                    type='tel'
                    name='celular'
                    placeholder='Telefone Celular'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.celular}
                    mask='(99)99999-9999'
                  />
                  {errors.celular && touched.celular && <div className='validation-error'>{errors.celular}</div>}
                </div>

                {isLoading ? (
                  <div style={{ textAlign: 'center', margin: '36px' }}>
                    <Spinner color='secondary' />
                  </div>
                ) : null}

                {sentWithSuccess ? (
                  <AuthenticationAlert
                    alertHighlight={colors.successHighlight}
                    alertBackground={colors.successBackground}
                    alertSuccess={true}
                  >
                    <p className='alert-label'>Sua mensagem foi enviada. Em breve entraremos em contato com você.</p>
                  </AuthenticationAlert>
                ) : null}

                {requestError ? (
                  <AuthenticationAlert
                    alertHighlight={colors.warningHighlight}
                    alertBackground={colors.warningBackground}
                    alertWarning={true}
                  >
                    <p className='alert-label'>
                      Houve um erro ao processar o seu pedido. Por favor, tente novamente mais tarde.
                    </p>
                  </AuthenticationAlert>
                ) : null}

                <GlobalButton
                  style={{ marginBottom: 16 }}
                  large={false}
                  dropShadow={true}
                  background={colors.primary}
                  labelColor={colors.light}
                  border={colors.primary}
                  hoverColor={colors.primary}
                  buttonLabel='Enviar Mensagem'
                  type='submit'
                />

                <GlobalButton
                  style={{ marginBottom: 16 }}
                  onClick={() => closeModal()}
                  buttonLabel='Cancelar'
                  background={colors.light}
                  labelColor={colors.primary}
                  border={colors.primary}
                  hoverColor={colors.lightShade}
                  type='button'
                />

                <FormikErrorFocus offset={0} align={'top'} focusDelay={50} ease={'linear'} duration={500} />
              </StyledForm>
            )}
          </Formik>
        </StyledModalBody>
      </Modal>
    </Main>
  )
}

export default FabOverlay
