import React, { useEffect, useState } from 'react'
import { Spinner } from 'reactstrap'
import api from '../../services/api/api'
import DonationItem from './DonationItem'


const DonationItemsContainer = () => {
  const [donationData, setDonationData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    api
      .get('regular-donation')
      .then(res => {
        setTimeout(() => {
          setDonationData(res.data)
          setIsLoading(false)
        }, 100)
      })
      .catch(() => {})
  }, [])

  return (
    <React.Fragment>
      {isLoading ? (
        <div style={{ textAlign: 'center', margin: '36px', height: '100vh' }}>
          <Spinner color='secondary' />
        </div>
      ) : donationData ? (
        donationData
          .sort((donationItemA, donationItemB) => {
            return donationItemA.donation_value > donationItemB.donation_value ? 1 : -1;
          })
          .map(donationItem => (
            <DonationItem
              donationTitle={donationItem.donation_title}
              donationDescription={donationItem.donation_description}
              donationValue={donationItem.donation_value}
              donationId={donationItem.id}
              hasDonationRewards={donationItem.has_donation_rewards}
              donationRewards={donationItem.donation_rewards}
              hasDonationInput={donationItem.has_donation_input}
              key={donationItem.id}
            >
              <ul>
                {donationItem.donation_rewards
                  ? donationItem.donation_rewards.map(rewardItem => <li>{rewardItem.reward}</li>)
                  : null}
              </ul>
            </DonationItem>
          ))
      ) : null}
    </React.Fragment>
  )
}

export default DonationItemsContainer
