import React, { useRef } from 'react'
import { useAtom } from 'jotai'

import { donationData } from '../../services/state/donationPurchaseData'
import { userData } from '../../services/state/appState'

import GlobalButton from '../../components/common/GlobalButton'

const SubmitPurchaseForm = () => {
  const [donationPurchaseData] = useAtom(donationData)
  const [userStateData] = useAtom(userData)

  let btnRef = useRef(null)
  
  const removeRegularDonationId = data => {
    if (data.isBuilderDonation === false) {
      return {
        value: data.value,
        isBuilderDonation: data.isBuilderDonation,
        hasRewards: data.hasRewards,
        rewards: data.rewards,
        donationIsRecurrent: data.donationIsRecurrent,
        paymentMethod: data.paymentMethod,
        paymentMethodData: data.paymentMethodData,
        hasAdressData: data.hasAdressData,
        adressData: data.adressData,
      }
    } else {
      return data
    }
  }

  const disableButtonAfterSubmit = () => {
    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "disabled")
    }
  }

  return (
    <form method='POST' action={`${process.env.REACT_APP_BASE_URL_API}/payment/form`} onSubmit={disableButtonAfterSubmit}>
      {Object.keys(removeRegularDonationId(donationPurchaseData)).map(stateKeyName => (
        <input
          type='hidden'
          name={stateKeyName}
          value={JSON.stringify(removeRegularDonationId(donationPurchaseData)[stateKeyName])}
        />
      ))}

      <input type='hidden' name='cmesToken' value={userStateData.token} />
      <GlobalButton 
        large={false} 
        dropShadow={true} 
        buttonLabel='Finalizar Doação' 
        type='submit'
        ref={btnRef}
      />
    </form>
  )
}

export default SubmitPurchaseForm
