import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useAtom } from 'jotai'
import { donationData } from '../../services/state/donationPurchaseData'

import BoletoForm from '../../components/forms/BoletoForm'
import PaymentSummaryCard from '../../components/payment/PaymentSummaryCard'
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap'

import mixins from '../../styles/mixins'
import theme from '../../styles/theme'

const { colors } = theme

const StyledGrid = styled(Container)`
  ${mixins.mainPageGrid}
  .grid-border-left {
    border-left: 1px solid ${colors.lightShade};
  }
`
const InformationSidepane = styled.div`
  .information-title {
    font-size: 22px;
    font-weight: 700;
  }
  ul {
    padding-left: 2.5rem;
    list-style-type: disc;
    margin: 30px 0;
  }
  ul li {
    color: ${colors.gray};
    font-size: 15px;
    margin-bottom: 1.6rem;
    line-height: 22px;
  }
`

const BoletoFormPage = () => {
  const [donationPurchaseData] = useAtom(donationData)

  return (
    <StyledGrid fluid>
      <Row>
        <Col>
          <Breadcrumb tag='nav' listTag='div'>
            <BreadcrumbItem>
              <Link to='/'>Início</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to='/doacao'>Tipo de doação</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Pagamento</BreadcrumbItem>
          </Breadcrumb>
          <h1 className='page-title'>Informações de pagamento</h1>

          <BoletoForm />
        </Col>
        <Col xs='4' className='grid-border-left'>
          <InformationSidepane>
            <PaymentSummaryCard
              donationValue={donationPurchaseData.value}
              donationRewards={donationPurchaseData.rewards}
              hasDonationRewards={donationPurchaseData.hasRewards}
            />
            <h3 className='information-title'>Informações sobre o pagamento:</h3>
            <ul>
              <li>
                Assim que seu pagamento for processado, você receberá um email de confirmação e uma cópia do boleto com o código de barras no endereço de email cadastrado.
              </li>
              <li>
                Coletaremos apenas as informações necessárias para processar o pagamento, e para enviar as recompensas,
                caso uma opção de doação com recompensa seja escolhida.
              </li>
            </ul>
          </InformationSidepane>
        </Col>
      </Row>
    </StyledGrid>
  )
}

export default BoletoFormPage
