import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumb, BreadcrumbItem, Spinner } from 'reactstrap'
import styled from 'styled-components'
import Layout from '../../components/common/Layout'
import SEO from '../../components/common/Seo'
import api from '../../services/api/api'
import mixins from '../../styles/mixins'
import theme from '../../styles/theme'

const { fonts, colors } = theme

const Main = styled.div`
  ${mixins.sidePadding}
  margin: 0 auto;
  width: 100%;
  max-width: 800px;

  ${mixins.markupStyles}

  .breadcrumb {
    background-color: ${colors.light};
    font-family: ${fonts.RobotoSlab};
    font-size: 16px;
  }
`
const Hero = styled.h1`
  font-size: 32px;
  margin: 0 auto;
  padding: 32px 16px;
  text-align: center;
`

const TermsOfPrivacy = () => {
  const [termsOfPrivacyContent, setTermsOfPrivacyContent] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    api
      .get('terms-of-privacy')
      .then(res => {
        setTimeout(() => {
          setTermsOfPrivacyContent(res.data)
          setIsLoading(false)
        }, 100)
      })
      .catch(() => {})
  }, [])

  return (
    <React.Fragment>
      {isLoading ? (
        <div style={{ textAlign: 'center', margin: '36px', height: '100vh' }}>
          <Spinner color='secondary' />
        </div>
      ) : termsOfPrivacyContent ? (
        <React.Fragment>
          <SEO
            title={`Termos de Privacidade - ${process.env.REACT_APP_BRAND_NAME_SHORT}`}
            pathname='termos-de-privacidade'
          />
          <Layout>
            <Hero>Termos e Privacidade</Hero>
            <Main>
              <Breadcrumb tag='nav' listTag='div'>
                <BreadcrumbItem>
                  <Link to='/'>Início</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>Termos de Privacidade</BreadcrumbItem>
              </Breadcrumb>

              <div
                dangerouslySetInnerHTML={{
                  __html: termsOfPrivacyContent.content_body,
                }}
              />
            </Main>
          </Layout>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  )
}

export default TermsOfPrivacy
