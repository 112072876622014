import Plyr from 'plyr-react'
import 'plyr-react/dist/plyr.css'
import React, { useState, useEffect, Fragment, useRef } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Breadcrumb, BreadcrumbItem, Col, Container, Row, Spinner } from 'reactstrap'
import styled from 'styled-components'
import GlobalButton from '../../components/common/GlobalButton'
import Layout from '../../components/common/Layout'
import SEO from '../../components/common/Seo'
import media from '../../styles/media'
import mixins from '../../styles/mixins'
import theme from '../../styles/theme'
import legacyApi from '../../services/api/legacyApi'
import { userData } from '../../services/state/appState'
import { useAtom } from 'jotai'
import { isUserLogged } from '../../services/utils/user'

const { colors } = theme

const StyledGrid = styled(Container)`
  .row {
    ${media.tinyDesktop`
      display: block;
    `}
  }
  .col-3 {
    ${media.tinyDesktop`
      max-width: 100%;
    `}
  }
  .col-4 {
    ${media.tinyDesktop`
      max-width: 100%;
    `}
  }
  .grid-border-left {
    border-left: 1px solid ${colors.lightShade};
  }
`
const Main = styled.div`
  ${mixins.sidePadding}
  ${mixins.desktopAlignCenter}
  padding-top: 60px;

  .breadcrumb {
    background-color: ${colors.light};
    font-size: 16px;
  }

  .page-title {
    font-size: 34px;
    color: ${colors.black};
    margin: 32px 0 16px;
  }
  .page-description {
    font-size: 16px;
    color: ${colors.gray};
  }
`
const InformationSidepane = styled.div`
  .information-title {
    font-size: 22px;
    font-weight: 700;
  }
  ul {
    padding-left: 2.5rem;
    list-style-type: disc;
    margin: 30px 0;
  }
  ul li {
    color: ${colors.gray};
    font-size: 15px;
    margin-bottom: 1.6rem;
    line-height: 22px;
  }
`

const MainCard = styled.div`
  ${mixins.rewardDonationCard};

  & > div.visible-section {
    div {
      width: 100%;
    }
  }
`

const SeriesPage = ({
  match: {
    params: { seriesId },
  },
}) => {
  const [loading, setLoading] = useState(true)
  const [loadingMarkVideoAsWatched, setLoadingMarkVideoAsWatched] = useState(false)
  const [videoData, setVideoData] = useState()
  const [atomUserData] = useAtom(userData)
  const scrollAnchorRef = useRef()

  const handleMarkVideoAsWatchedClick = () => {
    setLoadingMarkVideoAsWatched(true)
    legacyApi
      .post('', {
        op: 'seriesMarkVideoAsWatched',
        seriesId,
        cmesToken: atomUserData.token,
        videoId: videoData.video_id,
      })
      .then(res => {
        setVideoData(res.data?.current_video)
        setLoadingMarkVideoAsWatched(false)
        scrollAnchorRef.current?.scrollIntoView()
        // window.scrollTo(0, 140)
      })
      .catch(() => {})
  }
  useEffect(() => {
    if (atomUserData?.token) {
      legacyApi
        .post('', {
          op: 'seriesGetVideoData',
          seriesId,
          cmesToken: atomUserData.token,
        })
        .then(res => {
          setVideoData(res.data?.current_video)
          setLoading(false)
        })
        .catch(() => {})
    }
  }, [atomUserData, seriesId])

  if (!isUserLogged()) {
    return <Redirect to={`/login?seriesId=${seriesId}`} />
  }

  return (
    <React.Fragment>
      <SEO title={`Trilha de oração - ${process.env.REACT_APP_BRAND_NAME_SHORT}`} pathname={`/caminhos/${seriesId}`} />
      {loading ? (
        <div style={{ textAlign: 'center', margin: '36px', height: '100vh' }}>
          <Spinner color='secondary' />
        </div>
      ) : (
        <Layout>
          <Main>
            <StyledGrid fluid>
              <Row>
                <Col>
                  <Breadcrumb tag='nav' listTag='div'>
                    <BreadcrumbItem>
                      <Link to='/'>Início</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Trilha da oração</BreadcrumbItem>
                  </Breadcrumb>
                  <h1 ref={scrollAnchorRef} className='page-title'>
                    Inicie conosco a sua oração!
                  </h1>
                  <p className='page-description'>
                    {videoData.video_helper_description
                      ? 'Assista o vídeo, leia o texto de ajuda e clique no botão marcar como visto.'
                      : 'Assista o vídeo e clique no botão marcar como visto'}
                  </p>
                  <MainCard>
                    <div className='visible-section'>
                      <div>
                        <Plyr
                          source={{
                            type: 'video',
                            sources: [{ src: videoData.youtube_id, provider: 'youtube' }],
                          }}
                        />
                        <br />
                        <br />
                        {videoData.video_helper_description ? (
                          <Fragment>
                            <h3 className='card-title'>Texto de Ajuda:</h3>
                            <div
                              className='card-description'
                              dangerouslySetInnerHTML={{
                                __html: videoData.video_helper_description,
                              }}
                            />

                            <div className='card-description'></div>
                          </Fragment>
                        ) : null}

                        <br />
                        <GlobalButton
                          buttonLabel='Marcar vídeo como visto'
                          large={false}
                          onClick={handleMarkVideoAsWatchedClick}
                          dropShadow={true}
                          background={colors.primary}
                          labelColor={colors.light}
                          borderColor={colors.primary}
                          hoverColor={colors.primaryShade}
                          type='button'
                          loading={loadingMarkVideoAsWatched}
                          disabled={loadingMarkVideoAsWatched}
                        />
                      </div>
                    </div>
                  </MainCard>
                </Col>
                {videoData.video_sidebar_links && videoData.video_sidebar_links.length > 0 ? (
                  <Col xs='4' className='grid-border-left'>
                    <InformationSidepane>
                      <h3 className='information-title'>Material Complementar:</h3>
                      <ul>
                        <li>Clique para ter acesso ao material Complementar</li>
                        {videoData.video_sidebar_links.map(linkItem => {
                          return (
                            <li>
                              <a target={'_blank'} href={linkItem.link_url} rel='noreferrer'>
                                {linkItem.link_title}
                              </a>
                            </li>
                          )
                        })}
                      </ul>
                    </InformationSidepane>
                  </Col>
                ) : null}
              </Row>
            </StyledGrid>
          </Main>
        </Layout>
      )}
    </React.Fragment>
  )
}

export default SeriesPage
