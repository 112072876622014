import React from 'react'
import styled from 'styled-components'

import NavHeader from './NavHeader'
import NavFooter from './NavFooter'

const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex-flow: column;
  min-height: 100vh;
`
const HeaderWrapper = styled.div`
  flex: 0 1 auto;
`
const ContentWrapper = styled.div`
  flex: 1 1 auto;
`
const FooterWrapper = styled.div`
  flex: 0 1 40px;
`

const Layout = ({ children }) => {
  return (
    <Main>
      <HeaderWrapper>
        <NavHeader />
      </HeaderWrapper>
      <ContentWrapper>{children}</ContentWrapper>
      <FooterWrapper>
        <NavFooter />
      </FooterWrapper>
    </Main>
  )
}

export default Layout
