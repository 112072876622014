import api from '../api/api'

export const searchAddressByCep = async function (inputValue) {
  const searchHandler = async () => {
    return api
      .get(`/search/zipcode/BR/${inputValue}`)
      .then(async response => {
        if (response.data) {
          return response.data
        }
        if (response.data.error === true) {
          return false
        }
      })
      .catch(() => {
        return false
      })
  }

  return await searchHandler()
}
