import { atom } from 'jotai'

export const donationData = atom({
  id: null,
  value: null,
  isBuilderDonation: null,
  hasRewards: false,
  rewards: [],
  donationIsRecurrent: false,
  paymentMethod: null,
  paymentMethodData: {},
  hasAdressData: false,
  adressData: {}
})
