import React from 'react'
import styled from 'styled-components'

import theme from '../../styles/theme'
import mixins from '../../styles/mixins'

import SEO from '../../components/common/Seo'
import ProgressContentContainer from '../../components/home/ProgressContentContainer'

const { colors } = theme

const Main = styled.div`
  ${mixins.sidePadding};
  padding-top: 48px;
  margin: 0 auto;
  width: 100%;
  max-width: 1000px;
  font-size: 18px;
  font-weight: 700;
  color: ${colors.primary};
`

const ProgressTab = (props) => {
  let pageId = parseInt(props.match.params.pageId || '1')

  return (
    <React.Fragment>
      <SEO />
      <Main>
        <ProgressContentContainer pageId={pageId} />
      </Main>
    </React.Fragment>
  )
}

export default ProgressTab
