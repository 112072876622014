import React from 'react'
import CookieConsent from 'react-cookie-consent'

const CookieConsentComponent = () => {
  return (
    <CookieConsent
      location='bottom'
      buttonText='Eu Aceito'
      cookieName='cookieConsentAccepted'
      style={{ borderTop: 'solid 1px rgb(215, 216, 218)', background: '#f4f5f8', boxShadow: '0 0 120px 120px rgb(0 0 0 / 70%)' }}
      buttonStyle={{ backgroundColor: '#795548', color: '#ffffff', fontSize: '18px' }}
      expires={365}
    >
      <p style={{ color: 'black', fontSize: 16, marginBottom: 8 }}>Esse site usa cookies para melhorar sua experiência de uso.</p>
      <p style={{ color: 'black', fontSize: 13 }}>
        Ao clicar em "Eu Aceito" você concorda que leu e aceitou os Termos de Privacidade.
      </p>
    </CookieConsent>
  )
}

export default CookieConsentComponent
