import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import mixins from '../../styles/mixins'

import { Spinner } from 'reactstrap'
import api from '../../services/api/api'

const Main = styled.article`
  margin: 95px 60px 0;
  overflow-x: auto;
  ${mixins.markupStyles}
`

const AboutContentContainer = () => {
  const [aboutContent, setAboutContent] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    api
      .get('about-post')
      .then(res => {
        setTimeout(() => {
          setAboutContent(res.data)
          setIsLoading(false)
        }, 100)
      })
      .catch(() => {})
  }, [])

  return (
    <React.Fragment>
      {isLoading ? (
        <div style={{ textAlign: 'center', margin: '36px', height: '100vh' }}>
          <Spinner color='secondary' />
        </div>
      ) : (
        <Main
          dangerouslySetInnerHTML={{
            __html: aboutContent.content_body,
          }}
        />
      )}
    </React.Fragment>
  )
}

export default AboutContentContainer
