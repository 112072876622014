import { useAtom } from 'jotai'
import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import oracaoPlayLogo from '../../assets/imgs/OracaoPlay_Logo_HighRes.png'
import jovensSaradosLogo from '../../assets/imgs/js-black.png'
import SEO from '../../components/common/Seo'
import LoginForm from '../../components/forms/LoginForm'
import { userData } from '../../services/state/appState'
import media from '../../styles/media'
import theme from '../../styles/theme'

const { colors } = theme

const Main = styled.div`
  font-size: 16px;
  background-color: ${colors.lightTint};
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  .form-container {
    background-color: #fff;
    width: 500px;
    box-shadow: 0 2px 20px #d4cae2;
    border-radius: 5px;
    padding: 36px;
    overflow-x: auto;

    ${media.tablet`
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    `}
  }
  .form-description {
    font-size: 22px;
    text-align: center;
  }
`
const HomeLogo = styled.img`
  width: 75px;
  margin: 0 auto;
  padding-bottom: 26px;
`

const LoginPage = ({ location: { search } }) => {
  const searchParsed = new URLSearchParams(search)
  const seriesId = searchParsed.get('seriesId')
  const [atomUserData] = useAtom(userData)

  if (atomUserData) {
    return <Redirect to={seriesId ? `/caminhos/${seriesId}` : '/'} />
  }

  return (
    <Main>
      <SEO title='Login - Financiamento Coletivo' />
      <div className='form-container'>
        <Link to='/'>
          <HomeLogo
            src={process.env.REACT_APP_BRAND_ALTERNATE_LOGO === '1' ? jovensSaradosLogo : oracaoPlayLogo}
            alt={`Logo ${process.env.REACT_APP_BRAND_NAME_SHORT}`}
          />
        </Link>
        <h3 className='form-description'>Faça login com sua conta {process.env.REACT_APP_BRAND_NAME_SHORT}.</h3>
        <LoginForm />
      </div>
    </Main>
  )
}

export default LoginPage
