import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'
import InputMask from 'react-input-mask'
import FormikErrorFocus from 'formik-error-focus'
import { useAtom } from 'jotai'

import { donationData } from '../../services/state/donationPurchaseData'
import { userData } from '../../services/state/appState'
import { searchAddressByCep } from '../../services/utils/searchAddressByCep'

import GlobalButton from '../common/GlobalButton'
import { Container, Row, Col, Spinner } from 'reactstrap'

import media from '../../styles/media'
import mixins from '../../styles/mixins'
import theme from '../../styles/theme'
import { isUserPermanentlyLogged, loginUser } from '../../services/utils/user'

const { colors } = theme

const StyledForm = styled.form`
  ${mixins.formStyles}
`
const StyledGrid = styled(Container)`
  ${mixins.mainPageGrid}

  .col {
    ${media.tinyDesktop` padding: 0; `}
  }
  .col-3 {
    ${media.tinyDesktop` padding: 0; `}
  }
  .col-4 {
    ${media.tinyDesktop` padding: 0; `}
  }
  .spinner-container {
    width: 24px;
    display: flex;
    align-items: center;
    margin-left: 18px;
    
    position: relative;
    top: 6px;
  }
  .full-width {
    width: 100%;
  }
`

const AdressForm = () => {
  const history = useHistory()

  const cepSearchHandler = (data, setFieldValue) => {
    setFieldValue('cidade', data.cityName)
    setFieldValue('bairro', data.neighborhood)
    setFieldValue('endereco', data.streetName)
    setFieldValue('estado', data.stateName)

    // Fields that are not updated by the CEP will be wiped clean
    setFieldValue('numero', '')
    setFieldValue('referencia', '')
    setFieldValue('complemento', '')
  }

  const [cepIsLoading, setCepIsLoading] = useState(false)
  const [donationPurchaseData, setDonationPurchaseData] = useAtom(donationData)
  const [atomUserData, setAtomUserData] = useAtom(userData)

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        endereco: atomUserData.endereco ? atomUserData.endereco : '',
        cep: atomUserData.cep ? atomUserData.cep : '',
        numero: atomUserData.endereco_numero ? atomUserData.endereco_numero : '',
        complemento: atomUserData.endereco_complemento ? atomUserData.endereco_complemento : '',
        bairro: atomUserData.endereco_bairro ? atomUserData.endereco_bairro : '',
        referencia: '',
        cidade: atomUserData.cidade_nome ? atomUserData.cidade_nome : '',
        estado: atomUserData.estado_nome ? atomUserData.estado_nome : '',
      }}
      validationSchema={Yup.object().shape({
        endereco: Yup.string().required('Insira o endereço'),
        cep: Yup.string().required('Insira o CEP'),
        numero: Yup.string().required('Insira o número de residência'),
        complemento: Yup.string(),
        bairro: Yup.string().required('Insira o nome do bairro'),
        referencia: Yup.string(),
        cidade: Yup.string().required('Insira o nome da cidade'),
        estado: Yup.string().required('Insira o nome do estado'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        await setDonationPurchaseData({
          ...donationPurchaseData,
          adressData: values,
          hasAdressData: true
        })

        const updatedUserData = {
          ...atomUserData,
          cidade_nome: values.cidade,
          estado_nome: values.estado,
          endereco: values.endereco,
          cep: values.cep,
          endereco_numero: values.numero,
          endereco_complemento: values.complemento,
          endereco_bairro: values.bairro,
        }

        await setAtomUserData(updatedUserData)
        await loginUser(updatedUserData, isUserPermanentlyLogged())

        history.push('/pagamento?active-step=payment-summary')

        setSubmitting(false)
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
        <StyledForm onSubmit={handleSubmit}>
          <StyledGrid fluid>
            <Row>
              <Col xs='3' style={{ display: 'flex'}}>
                <div className='form-control-container full-width'>
                  <label htmlFor='cep'>CEP</label>
                  <InputMask
                    type='tel'
                    autoComplete='postal-code'
                    name='cep'
                    placeholder='_____-___'
                    onChange={e => {
                      handleChange(e)
                      let cepValue = e.currentTarget.value
                      if (cepValue.replace(/\D/g, '').length === 8) {
                        setCepIsLoading(true)
                        searchAddressByCep(cepValue).then(
                          data => {
                            if (data) {
                              cepSearchHandler(data, setFieldValue)
                              setCepIsLoading(false)
                            } else {
                              setCepIsLoading(false)
                            }
                          }
                        )
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.cep}
                    mask='99999-999'
                  />
                  {errors.cep && touched.cep && (
                    <div className='validation-error'>{errors.cep}</div>
                  )}
                </div>
                {cepIsLoading ? (
                  <div className='spinner-container'>
                    <Spinner/>
                  </div>
                ) : null}
              </Col>
              <Col>
                <div className='form-control-container'>
                  <label htmlFor='endereco'>Endereço</label>
                  <input
                    type='text'
                    inputMode='text'
                    autoComplete='street-address'
                    name='endereco'
                    placeholder='ex: Rua Brigadeiro Faria Lima'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.endereco}
                    disabled={cepIsLoading}
                  />
                  {errors.endereco && touched.endereco && <div className='validation-error'>{errors.endereco}</div>}
                </div>
              </Col>
              <Col xs='3'>
                <div className='form-control-container'>
                  <label htmlFor='numero'>Número</label>
                  <input
                    type='tel'
                    autoComplete='off'
                    name='numero'
                    placeholder='ex: 1541'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.numero}
                    disabled={cepIsLoading}
                  />
                  {errors.numero && touched.numero && <div className='validation-error'>{errors.numero}</div>}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='form-control-container'>
                  <label htmlFor='complemento'>Complemento (opcional)</label>
                  <input
                    type='tel'
                    autoComplete='off'
                    name='complemento'
                    placeholder='Ex: Bloco X, apt 204'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.complemento}
                    disabled={cepIsLoading}
                  />
                  {errors.complemento && touched.complemento && (
                    <div className='validation-error'>{errors.complemento}</div>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs='4'>
                <div className='form-control-container'>
                  <label htmlFor='bairro'>Bairro</label>
                  <input
                    type='text'
                    inputMode='text'
                    autoComplete='off'
                    name='bairro'
                    placeholder='Ex: Jardim Esmeralda'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.bairro}
                    disabled={cepIsLoading}
                  />
                  {errors.bairro && touched.bairro && <div className='validation-error'>{errors.bairro}</div>}
                </div>
              </Col>
              <Col>
                <div className='form-control-container'>
                  <label htmlFor='referencia'>Ponto de referência (opcional)</label>
                  <input
                    type='text'
                    inputMode='text'
                    autoComplete='off'
                    name='referencia'
                    placeholder='Ex: Próximo à panificação Guanabara'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.referencia}
                    disabled={cepIsLoading}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs='4'>
                <div className='form-control-container'>
                  <label htmlFor='cidade'>Cidade</label>
                  <input
                    type='text'
                    inputMode='text'
                    autoComplete='off'
                    name='cidade'
                    placeholder='Ex: São Paulo'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.cidade}
                    disabled={cepIsLoading}
                  />
                  {errors.cidade && touched.cidade && <div className='validation-error'>{errors.cidade}</div>}
                </div>
              </Col>
              <Col>
                <div className='form-control-container'>
                  <label htmlFor='estado'>Estado</label>
                  <input
                    type='text'
                    inputMode='text'
                    autoComplete='off'
                    name='estado'
                    placeholder='Ex: São Paulo'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.estado}
                    disabled={cepIsLoading}
                  />
                  {errors.estado && touched.estado && <div className='validation-error'>{errors.estado}</div>}
                </div>
              </Col>
            </Row>
          </StyledGrid>

          <GlobalButton
            large={false}
            dropShadow={true}
            background={colors.primary}
            labelColor={colors.light}
            border={colors.primary}
            hoverColor={colors.primary}
            buttonLabel='Continuar com o pagamento'
            type='submit'
          />

          <FormikErrorFocus
            offset={0}
            align={'top'}
            focusDelay={50}
            ease={'linear'}
            duration={500}
          />
        </StyledForm>
      )}
    </Formik>
  )
}

export default AdressForm
