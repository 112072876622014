import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Breadcrumb, BreadcrumbItem, Col, Container, Row, Spinner } from 'reactstrap'
import { Link } from 'react-router-dom'

import theme from '../../styles/theme'
import media from '../../styles/media'
import api from '../../services/api/api'
import BuilderDonationItem from '../builder/BuilderDonationItem'

const { colors } = theme

const StyledGrid = styled(Container)`
  .row {
    ${media.tinyDesktop`
      display: block;
    `}
  }
  .col-3 {
    ${media.tinyDesktop`
      max-width: 100%;
    `}
  }
  .col-4 {
    ${media.tinyDesktop`
      max-width: 100%;
    `}
  }
  .grid-border-left {
    border-left: 1px solid ${colors.lightShade};
  }
`
const InformationSidepane = styled.div`
  .information-title {
    font-size: 22px;
    font-weight: 700;
  }
  ul {
    padding-left: 2.5rem;
    list-style-type: disc;
    margin: 30px 0;
  }
  ul li {
    color: ${colors.gray};
    font-size: 15px;
    margin-bottom: 1.6rem;
    line-height: 22px;
  }
`

const SelectLocation = ({ locationId, locationTitle, locationDescription }) => {

  const [builderData, setBuilderData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [currentLocationId, setCurrentLocationId] = useState(null)

  useEffect(() => {
    if (locationId !== currentLocationId) {
      setIsLoading(true)
    }

    api.get(`builder-donation/${locationId}`).then(res => {
      setTimeout(() => {
        setCurrentLocationId(locationId)
        setBuilderData(res.data)
        setIsLoading(false)
      }, 100)
    }).catch(() => {})
  }, [locationId, currentLocationId])


  return (
    <React.Fragment>
      {isLoading ? (
        <div style={{ textAlign: 'center', margin: '36px', height: '100vh' }}>
          <Spinner color='secondary' />
        </div>
      ) : (
        <StyledGrid fluid>
          <Row>
            <Col>
              <Breadcrumb tag='nav' listTag='div'>
                <BreadcrumbItem>
                  <Link to='/'>Início</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>Tipo de doação</BreadcrumbItem>
              </Breadcrumb>
              <h1 className='page-title'>{locationTitle}</h1>
              <p className='page-description'>{locationDescription}</p>
              <p className='page-description'>
                Agora, selecione qual o item do local que você gostaria de contribuir:
              </p>
              {isLoading ? (
                <div style={{ textAlign: 'center', margin: '36px', height: '100vh' }}>
                  <Spinner color='secondary' />
                </div>
              ) : builderData ? (
                <React.Fragment>
                  {builderData.donation_individual_items
                    ? builderData.donation_individual_items.map(donationItem => (
                      <BuilderDonationItem
                        itemTitle={donationItem.item_title}
                        itemValue={donationItem.item_value}
                        itemHasRewards={donationItem.item_has_rewards}
                        itemRewards={donationItem.item_rewards}
                        locationId={locationId}
                        itemId={donationItem.item_id}
                        key={donationItem.item_id}
                      >
                        {donationItem.item_has_rewards ? (
                          <ul>
                            {donationItem.item_has_rewards
                              ? donationItem.item_rewards.map(rewardItem => <li>{rewardItem.reward}</li>)
                              : null}
                          </ul>
                        ) : null}
                      </BuilderDonationItem>
                    ))
                    : null}
                </React.Fragment>
              ) : null}
            </Col>
            <Col xs='4' className='grid-border-left'>
              <InformationSidepane>
                <h3 className='information-title'>Informações sobre as doações:</h3>
                <ul>
                  <li>
                    As doações dessa modalidade são na modalidade de <b>"Benfeitor Construtor"</b>. Caso queira fazer
                    uma doação regular, ou ver opções de doações com recompensas, visite a seção de{' '}
                    <Link to='/doacao'>Doações</Link>.
                  </li>
                  <li>As recompensas são entregues em todo o território brasileiro.</li>
                  <li>
                    Coletaremos apenas as informações necessárias para processar o pagamento, e para enviar as
                    recompensas, caso uma opção de doação com recompensa seja escolhida.
                  </li>
                </ul>
              </InformationSidepane>
            </Col>
          </Row>
        </StyledGrid>
      )}
    </React.Fragment>
  )
}

export default SelectLocation
