import React from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap'
import styled from 'styled-components'
import Layout from '../../components/common/Layout'
import SEO from '../../components/common/Seo'
import DonationItemsContainer from '../../components/donation/DonationItemsContainer'
import media from '../../styles/media'
import mixins from '../../styles/mixins'
import theme from '../../styles/theme'

const { colors } = theme

const StyledGrid = styled(Container)`
  .row {
    ${media.tinyDesktop`
      display: block;
    `}
  }
  .col-3 {
    ${media.tinyDesktop`
      max-width: 100%;
    `}
  }
  .col-4 {
    ${media.tinyDesktop`
      max-width: 100%;
    `}
  }
  .grid-border-left {
    border-left: 1px solid ${colors.lightShade};
  }
`
const Main = styled.div`
  ${mixins.sidePadding}
  ${mixins.desktopAlignCenter}
  padding-top: 60px;

  .breadcrumb {
    background-color: ${colors.light};
    font-size: 16px;
  }

  .page-title {
    font-size: 34px;
    color: ${colors.black};
    margin: 32px 0 16px;
  }
  .page-description {
    font-size: 16px;
    color: ${colors.gray};
  }
`
const InformationSidepane = styled.div`
  .information-title {
    font-size: 22px;
    font-weight: 700;
  }
  ul {
    padding-left: 2.5rem;
    list-style-type: disc;
    margin: 30px 0;
  }
  ul li {
    color: ${colors.gray};
    font-size: 15px;
    margin-bottom: 1.6rem;
    line-height: 22px;
  }
`

const DonationPage = () => {
  return (
    <React.Fragment>
      <SEO title={`Escolha sua forma de doação - ${process.env.REACT_APP_BRAND_NAME_SHORT}`} pathname='/doacao' />
      <Layout>
        <Main>
          <StyledGrid fluid>
            <Row>
              <Col>
                <Breadcrumb tag='nav' listTag='div'>
                  <BreadcrumbItem>
                    <Link to='/'>Início</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Tipo de doação</BreadcrumbItem>
                </Breadcrumb>
                <h1 className='page-title'>Seja parte dessa história e faça já sua doação!</h1>
                <p className='page-description'>Escolha uma das opções de contribuição abaixo:</p>

                <React.Fragment>
                  <DonationItemsContainer />
                </React.Fragment>
              </Col>
              <Col xs='4' className='grid-border-left'>
                <InformationSidepane>
                  <h3 className='information-title'>Informações sobre as doações:</h3>
                  <ul>
                    <li>Coletaremos apenas as informações necessárias para processar o pagamento.</li>
                  </ul>
                </InformationSidepane>
              </Col>
            </Row>
          </StyledGrid>
        </Main>
      </Layout>
    </React.Fragment>
  )
}

export default DonationPage
