import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { AiFillWarning, AiFillCheckCircle } from 'react-icons/ai'

import theme from '../../styles/theme'
const { colors } = theme

const Alert = styled.div.attrs(props => ({
  alertBackground: props.alertBackground || colors.successBackground,
  alertHighlight: props.alertHighlight || colors.successHighlight,
  alertIconBackground: props.alertIconBackground || 'white',
}))` 
  background: ${props => props.alertBackground};
  border-left: 3px solid ${props => props.alertHighlight};
  position: relative;
  padding: 24px 32px;
  margin-top: 24px;
  margin-bottom: 24px;
  border-radius: 6px 6px 6px 3px;
  .icon-wrapper {
    background-color: ${props => props.alertIconBackground};
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    padding: 8px;
    border-radius: 50%;
  }
  .icon {
    color: ${props => props.alertHighlight};
    width: 34px;
    height: 34px;
  }
`

const AuthenticationAlert = ({
  alertBackground, // String(required): Background color
  alertIconBackground, //String: Color of the background circle behind the icon
  alertHighlight, // String(required): Border and icon colors
  alertWarning, // Boolean: Determine if it should use the warning icon
  alertSuccess, // Boolean: Determine if it should use the success icon
  children
}) => {
  return (
    <Alert
      alertBackground={alertBackground}
      alertHighlight={alertHighlight}
      alertIconBackground={alertIconBackground}
    >
      <div className='icon-wrapper'>
        { alertWarning ? (
          <AiFillWarning className='icon' />
        ) : null}
        { alertSuccess ? (
          <AiFillCheckCircle className='icon' />
        ) : null}
      </div>
      {children}
    </Alert>
  )
}

AuthenticationAlert.propTypes = {
  alertBackground: PropTypes.string.isRequired,
  alertHighlight: PropTypes.string.isRequired,
  alertWarning: PropTypes.bool,
  alertSuccess: PropTypes.bool,
}

export default AuthenticationAlert