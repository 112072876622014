export const getUser = function () {
  const localUserData = JSON.parse(localStorage.getItem('user'))
  const sessionUserData = JSON.parse(sessionStorage.getItem('user'))

  if (localUserData) {
    return localUserData
  } else if (sessionUserData) {
    return sessionUserData
  } else {
    return null
  }
}

export const isUserLogged = function () {
  return getUser() ? true : false
}

export const loginUser = function (data, keepLogged = false) {

  if (keepLogged) {
    localStorage.setItem('user', JSON.stringify(data))
  } else {
    sessionStorage.setItem('user', JSON.stringify(data))
  }

}

export const logoutUser = function () {
  localStorage.removeItem('user')
  sessionStorage.removeItem('user')
  sessionStorage.removeItem('donationData')
}

export const isUserPermanentlyLogged = function () {
  return !sessionStorage.getItem('user')
}