import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useAtom } from 'jotai'

import { donationData } from '../../services/state/donationPurchaseData'
import { userData } from '../../services/state/appState'
import api from '../../services/api/api'

import theme from '../../styles/theme'
import mixins from '../../styles/mixins'

import PaymentSummaryCard from '../../components/payment/PaymentSummaryCard'
import SubmitPurchaseForm from '../../components/payment/SubmitPurchaseForm'
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap'

const { colors } = theme

const StyledGrid = styled(Container)`
  ${mixins.mainPageGrid}
  .grid-border-left {
    border-left: 1px solid ${colors.lightShade};
  }
`
const InformationSidepane = styled.div`
  .information-title {
    font-size: 22px;
    font-weight: 700;
  }
  ul {
    padding-left: 2.5rem;
    list-style-type: disc;
    margin: 30px 0;
  }
  ul li {
    color: ${colors.gray};
    font-size: 15px;
    margin-bottom: 1.6rem;
    line-height: 22px;
  }
`

const PaymentSummaryPage = () => {
  const [donationPurchaseData] = useAtom(donationData)
  const [atomUserData] = useAtom(userData)

  const donationId = donationPurchaseData.isBuilderDonation ? donationPurchaseData.id
    : null

  useEffect(() => {
    sessionStorage.setItem('donationData', JSON.stringify(donationPurchaseData))

    api.post('/payment/attempt', {
      cmesToken: atomUserData.token,
      value: donationPurchaseData.value,
      id: donationId
    })
    .then(_ => null)
    .catch(_ => null)
  }, [donationPurchaseData, atomUserData.token, donationId])

  return(
    <StyledGrid fluid>
      <Row>
        <Col>
          <Breadcrumb tag='nav' listTag='div'>
            <BreadcrumbItem>
              <Link to='/'>Início</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to='/doacao'>Tipo de doação</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Pagamento</BreadcrumbItem>
          </Breadcrumb>
          <h1 className='page-title'>Resumo de compra</h1>
          <p className='page-description'>Revise as informações em clique em "Finalizar Doação" quando estiver pronto:</p>

          <PaymentSummaryCard
            donationId={donationPurchaseData.id}
            locationId={donationPurchaseData.locationId}
            donationValue={donationPurchaseData.value}
            hasDonationRewards={donationPurchaseData.hasRewards}
            paymentMethod={donationPurchaseData.paymentMethod}
            paymentMethodData={donationPurchaseData.paymentMethodData}
            hasAdressData={donationPurchaseData.hasAdressData}
            adressData={donationPurchaseData.adressData}
            isBuilderDonation={donationPurchaseData.isBuilderDonation}
          />

          <SubmitPurchaseForm />

        </Col>
        <Col xs='4' className='grid-border-left'>
          <InformationSidepane>
            <h3 className='information-title'>Informações sobre o pagamento:</h3>
            <ul>
              <li>
                Aceitamos as bandeiras <b>MasterCard</b>, <b>Visa</b>, <b>ELO</b>, <b>HiperCard</b>,{' '}
                <b>American Express</b>.
              </li>
              <li>
                Assim que seu pagamento for processado, você receberá um email de confirmação no endereço de email
                cadastrado.
              </li>
              <li>
                Coletaremos apenas as informações necessárias para processar o pagamento, e para enviar as recompensas,
                caso uma opção de doação com recompensa seja escolhida.
              </li>
            </ul>
          </InformationSidepane>
        </Col>
      </Row>
    </StyledGrid>
  )
}

export default PaymentSummaryPage
