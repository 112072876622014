import { css } from 'styled-components'
import media from './media'
import theme from './theme'

const { colors, fonts } = theme

const mixins = {
  flexCenter: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  flexLeft: css`
    display: flex;
    justify-content: left;
  `,
  flexBetween: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  desktopAlignCenter: css`
    margin: 0 auto;
    width: 100%;
    right: 0;
    left: 0;
    max-width: 1500px;
  `,
  sidePadding: css`
    padding-left: 50px;
    padding-right: 50px;
    ${media.phablet`padding-left: 15px; padding-right: 15px;`};
  `,

rewardDonationCard: css`
    padding: 46px 64px;
    border: solid 1px ${colors.lightShade};
    border-radius: 5px;
    max-width: 780px;
    margin: 50px 0;
    ${media.tinyDesktop`
      padding: 16px 24px;
    `}
    .visible-section {
      display: flex;
      align-items: baseline;

      ul {
        padding-left: 2.5rem;
        list-style-type: disc;
        margin: 10px 0;
      }
      ul li {
        font-family: ${fonts.Merriweather};
        color: ${colors.gray};
        font-size: 15px;
        margin-bottom: 1.6rem;
        line-height: 18px;
      }

      input {
        position: relative;
        top: 5px;
        width: 25px;
        height: 25px;
        cursor: pointer;
      }
      .card-title {
        font-family: ${fonts.RobotoSlab};
        font-weight: 700;
        font-size: 20px;
        color: ${colors.primary};
        padding-bottom: 30px;
        margin: 0;
      }
      .card-description {
        font-family: ${fonts.Merriweather};
        font-size: 16px;
        color: ${colors.black};
      }
      .card-rewards-title {
        font-size: 16px;
        color: ${colors.black};
        font-weight: 700;
        text-transform: uppercase;
        margin-top: 22px;
      }
    }
  `,
  rewardDonationCardAnimation: css `
    transition-timing-function: ease, linear, ease;
    transition-duration: 0.3s, 0.3s, 0.3s;
    cursor: pointer;

    :hover,
    :focus {
      transform: scale(1.02);
      box-shadow: ${colors.cardDropShadow};
    }
  `,
  collapsableSection: css`
    margin-top: 32px;
    .input-group {
      font-size: 16px;
    }
    .input-group-text {
      font-size: 18px;
      color: ${colors.light};
      background-color: ${colors.primary};
    }
    .form-control {
      height: 52px;
      font-size: 18px;
      border: 1px solid ${colors.primary};
    }
  `,

  // Properties used on all the content bellow the tabs on the main screen
  mainPageGrid: css`
    .row {
      ${media.desktop`
      display: block;
    `}
    }
    .col-3 {
      ${media.desktop`
      max-width: 100%;
      margin-top: 32px;
      margin-bottom: 32px;
    `}
    }
    .col-4 {
      ${media.desktop`
      max-width: 100%;
      margin-top: 32px;
      margin-bottom: 32px;
    `}
    }
    .builder-side-panel {
      ${media.tablet`
      margin: 0;
    `}
    }
  `,

  // Main properties for the side-pane cards found on home-page
  sidePaneCards: css`
    border-top: solid 8px ${colors.secondary};
    background-color: ${colors.background};
    margin: 36px 0;
    padding: 32px;
    width: 100%;
    ${media.thone`
      width: 100%;
    `}
  `,

  // Overrides the default MD styles on ReactStrap tabs
  tabStyleOverride: css`
    a {
      color: ${colors.primary};
      cursor: pointer;
      transition: 100ms ease-in-out;
    }
    .nav-link {
      padding: 2.5rem 4rem;
      border-width: 3px;
      border-color: ${colors.background};
      ${media.tinyDesktop`
        padding: 1.5rem 1.5rem;
      `}
      ${media.phablet`
        padding: 1rem 0.5rem;
      `}
    }
    .nav-link.active {
      background-color: ${colors.background};
      border-width: 3px;
      border-color: ${colors.background} ${colors.background} ${colors.primary};
      font-weight: 700;
    }
    .nav-link.active:hover {
      border-width: 3px;
      border-color: ${colors.background} ${colors.background} ${colors.primary};
    }
    .nav-link:hover {
      border-color: ${colors.background};
    }
    .nav {
      flex-wrap: nowrap;
    }
  `,

  // Main properties for form components
  formStyles: css`
    .form-control-container {
      margin: 26px 0;
    }
    .lower-section {
      display: flex;
      justify-content: space-between;
      margin: 32px 0;

      ${media.phablet` 
      display: block;
    `}
    }
    .validation-error {
      position: relative;
      text-align: left;
      margin-top: 4px;
      background-color: rgb(212, 70, 95);
      width: 100%;
      max-width: 100%;
      color: rgb(255, 255, 255);
      border-radius: 4px;
      padding: 8px 10px;
      font-size: 13px;
      font-weight: 700;
      box-shadow: 0 2px 5px #d4cae2;
    }
    label {
      font-size: 14px;
      padding-bottom: 8px;
      color: ${colors.black};
    }
    input, select {
      font-size: 15px;
      padding: 12px 16px;
      border: 1px solid ${colors.lightShade};
      background-color: ${colors.light};
      border-radius: 3px;
      width: 100%;
    }
    textarea {
      font-size: 15px;
      padding: 12px 16px;
      border: 1px solid ${colors.lightShade};
      background-color: ${colors.light};
      border-radius: 3px;
      width: 100%;
      height: 170px;
    }
    input,
    select,
    textarea {
      color: ${colors.black};
    }
    input, select, textarea::placeholder {
      color: ${colors.black};
    }
    input, select, textarea:focus, input, select, textarea:hover {
      outline-color: ${colors.secondary};
      outline-width: 1px;
    }
    a {
      font-size: 14px;
      color: ${colors.primary};
    }
    .checkbox {
      width: 16px;
      height: 16px;
    }
    .remain-connected {
      padding-left: 6px;
      position: relative;
      bottom: 2px;
    }
    .signup-link {
      font-size: 16px;
    }
    .sub-text {
      font-size: 18px;
      color: ${colors.gray};
    }
  `,

  // Main properties for markup content that will come from the CRM
  markupStyles: css`
    font-size: 16px;
    overflow-wrap: break-word;
    font-family: ${fonts.Merriweather};
    font-weight: 400;
    ${media.tablet`
      margin: 0;
    `}

    span {
      color: ${colors.black};
    }
    strong {
      font-weight: 700;
    }
    p {
      color: ${colors.black};
      line-height: 1.7;
      padding: 10px 0;
      ${media.thone`
      font-size: 16px;
      line-height: 1.6;
    `}
    }
    ul {
      padding-left: 2.5rem;
      list-style-type: disc;
      margin-bottom: 30px;
    }
    ul li {
      color: ${colors.gray};
      margin-bottom: 1.6rem;
      line-height: 30px;
      ${media.thone`
      font-size: 16px;
      line-height: 1.6;
    `}
    }
    }
    h1 {
      font-family: ${fonts.RobotoSlab};
      color: ${colors.primary}!important;
      font-size: 36px;
      font-weight: 400;
      line-height: 1.2;
      padding: 20px 0 20px 0;
    }
    h1 strong {
      color: ${colors.primary}!important;
    }
    h2 {
      font-family: ${fonts.RobotoSlab};
      color: ${colors.black};
      font-size: 24px;
      padding: 20px 0 20px 0;
    }
    h3 {
      font-family: ${fonts.RobotoSlab};
      color: ${colors.black};
      font-size: 20px;
      font-weight: 700;
      padding: 20px 0 20px 0;
    }
    hr {
      display: block;
      background: ${colors.lightShade};
      margin-block-start: 0.5em;
      margin-block-end: 0.5em;
      overflow: hidden;
      border-style: solid;
      border-width: 1px;
      border-color: ${colors.lightShade};
    }
    blockquote {
      font-size: 20px;
      padding: 3rem;
      color: ${colors.gray};
      font-style: italic;
      text-align: center;
    }
    img {
      width: 100%;
      border-width: 5px;
    }
    figcaption {
      font-size: 14px;
      color: ${colors.gray};
      text-align: center;
      padding: 20px 0;
    }
    b, i, table {
      color: ${colors.black};
    }
    
    //Custom classes for the about page
    . 
  `
}

export default mixins
