import React, { useState, useEffect } from 'react'

import { Spinner } from 'reactstrap'
import DonationAndRewardsCard from './DonationAndRewardsCard'
import api from '../../services/api/api'

const DonationAndRewardsPane = () => {
  const [fixedDonations, setFixedDonations] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    api.get('fixed-regular-donations').then(res => {
      setTimeout(() => {
        setFixedDonations(res.data)
        setIsLoading(false)
      }, 100)
    }).catch(() => {})
  }, [])

  return (
    <React.Fragment>
      {isLoading ? (
        <div style={{ textAlign: 'center', margin: '36px', height: '25vh' }}>
          <Spinner color='secondary' />
        </div>
      ) : fixedDonations ? (
        fixedDonations.map(item => (
          <DonationAndRewardsCard
            donationTitle={item.donation_title}
            donationDescription={item.donation_description}
            donationValue={item.donation_value}
            donationRewards={item.donation_rewards}
            hasDonationRewards={item.has_donation_rewards}
            donationId={item.id}
            key={item.id}
          />
        ))
      ) : null}
    </React.Fragment>
  )
}

export default DonationAndRewardsPane
