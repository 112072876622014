import React from 'react'
import styled from 'styled-components'

import { useHistory } from 'react-router-dom'
import { Container, Row, Col } from 'reactstrap'
import GlobalButton from '../common/GlobalButton'

import mixins from '../../styles/mixins'
import theme from '../../styles/theme'
import { useAtom } from 'jotai'
import { donationData } from '../../services/state/donationPurchaseData'

const { colors } = theme

const Main = styled.div`
  ${mixins.rewardDonationCard};
  .card-donation-value {
    font-size: 22px;
  }
`
const StyledContainer = styled(Container)`
  .row {
    align-items: center;
  }
  .col,
  .col-4 {
    padding-left: 0;
    padding-right: 10px;
  }
  margin-top: 32px;
  .input-group {
    font-size: 16px;
  }
  .input-group-text {
    font-size: 18px;
    color: ${colors.light};
    background-color: ${colors.primary};
  }
  .form-control {
    height: 52px;
    font-size: 18px;
    border: 1px solid ${colors.primary};
  }
`

const BuilderDonationItem = ({ itemId, locationId, itemTitle, itemValue, itemHasRewards, itemRewards, children }) => {
  const history = useHistory()
  const [donationPurchaseData, setDonationPurchaseData] = useAtom(donationData)

  const onIndividualItemClick = function (itemId, itemValue, itemHasRewards, itemRewards) {
    setDonationPurchaseData({
      ...donationPurchaseData,
      locationId: locationId,
      id: itemId,
      value: itemValue,
      isBuilderDonation: true,
      hasRewards: itemHasRewards,
      rewards: itemRewards,
    })

    history.push(`/pagamento?active-step=payment-method`)
  }

  return (
    <Main>
      <div className='visible-section'>
        <div>
          <h3 className='card-title'>{itemTitle}</h3>
          <p className='card-donation-value'>R$ {itemValue}</p>
          {children}
        </div>
      </div>
      <StyledContainer>
        <Row>
          <Col xs='4'>
            <GlobalButton
              buttonLabel='Doar este item'
              large={false}
              dropShadow={true}
              background={colors.primary}
              labelColor={colors.light}
              borderColor={colors.primary}
              hoverColor={colors.primaryShade}
              onClick={() => onIndividualItemClick(itemId, itemValue, itemHasRewards, itemRewards)}
            />
          </Col>
        </Row>
      </StyledContainer>
    </Main>
  )
}

export default BuilderDonationItem
