import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { Provider } from 'jotai'
import React from 'react'
import OneSignal from 'react-onesignal'
import Routes from './routes'
import GlobalStyles from './styles/GlobalStyles'

require('dotenv').config()

Bugsnag.start({
  apiKey: '64ec8f821c91c6d59d685c7ab5b04e1d',
  plugins: [new BugsnagPluginReact()],
})

function App() {
  if (process.env.REACT_APP_ONESIGNAL_ID) {
    OneSignal.initialize(process.env.REACT_APP_ONESIGNAL_ID, {
      allowLocalhostAsSecureOrigin: true,
    })
  }

  const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

  return (
    <ErrorBoundary>
      <Provider>
        <GlobalStyles />
        <Routes />
      </Provider>
    </ErrorBoundary>
  )
}

export default App
