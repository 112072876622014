import React, { useState } from 'react'
import styled from 'styled-components'
import { Collapse } from 'reactstrap'
import { GoChevronDown } from 'react-icons/go'

import theme from '../../styles/theme'

const { colors } = theme

const Main = styled.div`
  margin-top: 32px;
  margin-bottom: 16px;
  font-size: 16px;
`
const QuestionContainer = styled.div`
  font-size: 16px;
  color: ${colors.gray};
  padding: 24px 18px;
  border: solid 1px ${colors.gray};
  border-radius: 5px;
  cursor: pointer;

  .question {
    display: flex;
    justify-content: space-between;
  }
`

const StyledCollapse = styled(Collapse)`
  font-weight: 400;
`

const FaqItems = ({ faqQuestion, faqAnswer }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  return (
    <Main>
      <div>
        <QuestionContainer onClick={toggle}>
          <div className='question'>
            <p>{faqQuestion}</p>
            <GoChevronDown />
          </div>
          <StyledCollapse isOpen={isOpen}>
            <p>{faqAnswer}</p>
          </StyledCollapse>
        </QuestionContainer>
      </div>
    </Main>
  )
}

export default FaqItems
