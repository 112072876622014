import { css } from 'styled-components'

// Roboto Slab
import RobotoSlabRegularWOFF from '../assets/fonts/RobotoSlab-Regular.woff'
import RobotoSlabRegularWOFF2 from '../assets/fonts/RobotoSlab-Regular.woff2'

// Merriweather
import MerriweatherRegularWOFF from '../assets/fonts/Merriweather-Regular.woff'
import MerriweatherRegularWOFF2 from '../assets/fonts/Merriweather-Regular.woff2'

const FontFaces = css `
  @font-face {
    font-family: 'Roboto-Slab';
    src: url(${RobotoSlabRegularWOFF}) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Roboto-Slab';
    src: url(${RobotoSlabRegularWOFF2}) format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Merriweather';
    src: url(${MerriweatherRegularWOFF}) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: block;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Merriweather';
    src: url(${MerriweatherRegularWOFF2}) format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: block;
    font-display: fallback;
  }
`

export default FontFaces
