import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { Spinner } from 'reactstrap'
import ProgressPostItem from '../home/ProgressPostItem'
import api from '../../services/api/api'

import theme from '../../styles/theme'
import mixins from '../../styles/mixins'

const { colors } = theme

const Pagination = styled.nav`
  ${mixins.desktopAlignCenter}
  align-items: center;
  display: flex;
  font-size: 1.8rem;
  justify-content: space-between;
  padding-bottom: 2.8rem;
`
const PaginationLink = styled(Link)`
  color: ${colors.primary};
  transition: all ease-in-out 0.1s;
  :hover {
    color: ${colors.secondary};
  }
`

const ProgressContentContainer = ({ pageId }) => {
  const [progressPostListing, setProgressPostListing] = useState([])
  const [currentPage, setCurrentPage] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (pageId !== currentPage) {
      setIsLoading(true)
    }

    api.get(`progress-pages/${pageId}`).then(res => {
      setTimeout(() => {
        setCurrentPage(pageId)
        setProgressPostListing(res.data)
        setIsLoading(false)
      }, 200)
    }).catch(() => {})
  }, [pageId, currentPage])

  const numPages = progressPostListing.total_pages
  const isFirst = progressPostListing.is_first
  const isLast = progressPostListing.is_last
  const prevPage = `/progresso/page/${progressPostListing.previous_page_id}`
  const nextPage = `/progresso/page/${progressPostListing.next_page_id}`

  return (
    <React.Fragment>
      {isLoading ? (
        <div style={{ textAlign: 'center', margin: '36px', height: '100vh' }}>
          <Spinner color='secondary' />
        </div>
      ) : progressPostListing ? (
        progressPostListing.page_data.edges.map(updateItem => (
          <ProgressPostItem
            updateNumber={updateItem.update_number}
            updateDate={updateItem.frontmatter.date}
            updateTitle={updateItem.frontmatter.title}
            updateExcerpt={updateItem.excerpt}
            updateId={updateItem.id}
            key={updateItem.id}
          />
        ))
      ) : null}

      <Pagination>
        {!isFirst && (
          <PaginationLink to={prevPage} rel='prev'>
            ← Anterior
          </PaginationLink>
        )}

        <span>
          Página {currentPage} de {numPages}
        </span>

        {!isLast && (
          <PaginationLink to={nextPage} rel='next'>
            Próxima →
          </PaginationLink>
        )}
      </Pagination>
    </React.Fragment>
  )
}

export default ProgressContentContainer
