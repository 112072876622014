import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Breadcrumb, BreadcrumbItem, Col, Container, Row, Spinner } from 'reactstrap'
import { Link } from 'react-router-dom'

import theme from '../../styles/theme'
import media from '../../styles/media'
import api from '../../services/api/api'
import BuilderDonationItem from '../home/BuilderDonationItem'

const { colors } = theme

const StyledGrid = styled(Container)`
  .row {
    ${media.tinyDesktop`
      display: block;
    `}
  }
  .col-3 {
    ${media.tinyDesktop`
      max-width: 100%;
    `}
  }
  .col-4 {
    ${media.tinyDesktop`
      max-width: 100%;
    `}
  }
  .grid-border-left {
    border-left: 1px solid ${colors.lightShade};
  }
`
const InformationSidepane = styled.div`
  .information-title {
    font-size: 22px;
    font-weight: 700;
  }
  ul {
    padding-left: 2.5rem;
    list-style-type: disc;
    margin: 30px 0;
  }
  ul li {
    color: ${colors.gray};
    font-size: 15px;
    margin-bottom: 1.6rem;
    line-height: 22px;
  }
`

const SelectLocation = ({ donationTitle, donationDescription }) => {
  const [mapData, setMapData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    api.get('builder-donation').then(res => {
      setMapData(res.data.slice(1))
      setIsLoading(false)
    }).catch(() => {})
  }, [])

  return (
    <React.Fragment>
      {isLoading ? (
        <div style={{ textAlign: 'center', margin: '36px', height: '100vh' }}>
          <Spinner color='secondary' />
        </div>
      ) : (
        <StyledGrid fluid>
          <Row>
            <Col>
              <Breadcrumb tag='nav' listTag='div'>
                <BreadcrumbItem>
                  <Link to='/'>Início</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>Tipo de doação</BreadcrumbItem>
              </Breadcrumb>
              <h1 className='page-title'>{donationTitle}</h1>
              <p className='page-description'>{donationDescription}</p>
              <p className='page-description'>
                Primeiro, selecione a seção do nosso convento com a qual você gostaria de contribuir:
              </p>
              {isLoading ? (
                <div style={{ textAlign: 'center', margin: '36px', height: '100vh' }}>
                  <Spinner color='secondary' />
                </div>
              ) : mapData ? (
                <React.Fragment>
                  {mapData.map(mapItem => (
                    <BuilderDonationItem
                      itemTitle={mapItem.donation_title}
                      itemDescription={mapItem.donation_description}
                      itemDonationPercentage={mapItem.donation_percentage}
                      itemDonationId={mapItem.id}
                      itemImage={mapItem.donation_img}
                      itemImageAlt={mapItem.donation_img_alt}
                      key={`map-item + ${mapItem.id}`}
                    />
                  ))}
                </React.Fragment>
              ) : null}
            </Col>
            <Col xs='4' className='grid-border-left'>
              <InformationSidepane>
                <h3 className='information-title'>Informações sobre as doações:</h3>
                <ul>
                  <li>
                    As doações dessa modalidade são na modalidade de <b>"Benfeitor Construtor"</b>. Caso queira fazer
                    uma doação regular, ou ver opções de doações com recompensas, visite a seção de{' '}
                    <Link to='/doacao'>Doações</Link>.
                  </li>
                  <li>As recompensas são entregues em todo o território brasileiro.</li>
                  <li>
                    Coletaremos apenas as informações necessárias para processar o pagamento, e para enviar as
                    recompensas, caso uma opção de doação com recompensa seja escolhida.
                  </li>
                </ul>
              </InformationSidepane>
            </Col>
          </Row>
        </StyledGrid>
      )}
    </React.Fragment>
  )
}

export default SelectLocation
