import React from 'react'
import styled from 'styled-components'
import { useAtom } from 'jotai'
import { useLocation, Redirect } from 'react-router-dom'
import { donationData } from '../../services/state/donationPurchaseData'
import { isUserLogged } from '../../services/utils/user'

import mixins from '../../styles/mixins'
import theme from '../../styles/theme'

import SEO from '../../components/common/Seo'
import Layout from '../../components/common/Layout'

import PaymentMethodPage from './PaymentMethodPage'
import CreditCardFormPage from './CreditCardFormPage'
import BoletoFormPage from './BoletoFormPage'
import AdressFormPage from './AdressFormPage'
import PaymentSummaryPage from './PaymentSummaryPage'

const { colors } = theme

const Main = styled.div`
  ${mixins.sidePadding}
  ${mixins.desktopAlignCenter}
  padding-top: 60px;

  .breadcrumb {
    background-color: ${colors.light};
    font-size: 16px;
  }
  .page-title {
    font-size: 34px;
    color: ${colors.black};
    padding-bottom: 16px;
  }
  .page-description {
    font-size: 16px;
    color: ${colors.gray};
  }
`

const PaymentPage = () => {
  const [donationPurchaseData] = useAtom(donationData)

  let query = new URLSearchParams(useLocation().search)

  // Checks for a logged user in localStorage/sessionStorage, redirecting them to the login page if there are none
  if (!isUserLogged()) {
    return <Redirect to='/guest-login?active-step=user-check' />
  }

  // Checks for a valid donation id, returning the user to the donation page if there is none
  if (!donationPurchaseData.id) {
    return <Redirect push to='/doacao' />
  }

  return (
    <React.Fragment>
      <SEO title='Pagamento - Financiamento Coletivo' />
      <Layout>
        <Main>
          {query.get('active-step') === 'payment-method' ? <PaymentMethodPage /> : null}

          {query.get('active-step') === 'payment-data' ? (
            query.get('payment-method') === 'cartao-de-credito' ? (
              <CreditCardFormPage />
            ) : (
              <BoletoFormPage />
            )
          ) : null}

          {query.get('active-step') === 'delivery-adress' ? <AdressFormPage /> : null}

          {query.get('active-step') === 'payment-summary' ? <PaymentSummaryPage /> : null}
        </Main>
      </Layout>
    </React.Fragment>
  )
}

export default PaymentPage
