import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

function ScrollToTop({ history }) {
  useEffect(() => {
    const unlisten = history.listen(({ pathname }) => {
      const tabsUrl = ['/sobre', '/faq', '/progresso', '/seja-um-construtor']

      if (tabsUrl.indexOf(pathname) > -1) {
        // element which needs to be scrolled to
        const element = document.querySelector('#home-tabs-container')
        if (element) {
          element.scrollIntoView()
        } else {
          window.scrollTo(0, 0)
        }
      } else {
        window.scrollTo(0, 0)
      }
    })
    return () => {
      unlisten()
    }
  }, [history])

  return null
}

export default withRouter(ScrollToTop)
