import React from 'react'
import styled from 'styled-components'

import theme from '../../styles/theme'
import mixins from '../../styles/mixins'
import media from '../../styles/media'

import { Container, Row, Col } from 'reactstrap'
import SEO from '../../components/common/Seo'
import FaqItemsContainer from '../../components/home/FaqItemsContainer'
// import UserAskQuestionItem from '../../components/home/UserAskQuestionItem'

const { colors } = theme

const Main = styled.div`
  ${media.tablet`
    padding-top: 36px;
  `}
  ${mixins.sidePadding}
  ${mixins.desktopAlignCenter}
  font-size: 18px;
  font-weight: 700;
  color: ${colors.primary};
`

const StyledGrid = styled(Container)`
  ${mixins.mainPageGrid}
`

const PageTitle = styled.h2`
  margin: 36px 0;
  font-size: 34px;
  font-weight: 400;
  color: ${colors.black};
`

const FaqTab = () => {
  return (
    <React.Fragment>
      <SEO />

      <Main>
        <StyledGrid fluid>
          <Row>
            <Col>
              <PageTitle>Perguntas Frequentes</PageTitle>
              <FaqItemsContainer />
            </Col>
            <Col xs='3'>
              {/* <UserAskQuestionItem /> */}
            </Col>
          </Row>
        </StyledGrid>
      </Main>
    </React.Fragment>
  )
}

export default FaqTab
