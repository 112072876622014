import { format, parse } from 'date-fns'


/* Formats monetary value strings to includ points between decimal units and decimal counts */
export const formatMonetary = (amount, decimalCount = 2, decimal = '.', thousands = ',') => {
  decimalCount = Math.abs(decimalCount)
  decimalCount = isNaN(decimalCount) ? 2 : decimalCount

  const negativeSign = amount < 0 ? '-' : ''

  let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString()
  let j = i.length > 3 ? i.length % 3 : 0

  return (
    negativeSign +
    (j ? i.substr(0, j) + thousands : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
    (decimalCount
      ? decimal +
        Math.abs(amount - i)
          .toFixed(decimalCount)
          .slice(2)
      : '')
  )
}

/* Replaces "/" with "-" in the date string, parses it, and formats it to match the way
it is accepted as a valid date string by the API */
export const formatDate = date => {
  const replace = date.replace(/\//g, '-')
  const parsedDate = parse(replace, 'dd-MM-yyyy', new Date())
  return format(parsedDate, 'yyyy-MM-dd')
}

/* Removes every non-numeric character from the cellphone number string */
export const formatCelNumber = celNumber => {
  return celNumber.replace(/\D/g, '')
}

/* Removes every non-numeric character from the CPF string */
export const formatCpf = cpfValue => {
  return cpfValue.replace(/\D/g, '')
}

/* Capitalizes every first letter of a sentence in a string */
export const capitalize = str => {
  const individualWords = str.split(' ')
  return individualWords
    .map(word => {
      return word[0].toUpperCase() + word.substring(1)
    })
    .join(' ')
}

/* Capitalizes every first letter of a sentence in a string */
export const uncapitalize = str => {
  return str.toLowerCase()
}
