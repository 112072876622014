import { atom } from 'jotai'
import { getUser } from '../utils/user'

/* State for the map modal on "Seja um Construtor" */
export const mapMenuIsClosed = atom(false)

/* State for the question modal in the FAQ page */
export const questionsModalIsOpen = atom(false)

/* State to check if a info is available for a given CEP */
export const cepInfoIsAvailable = atom(false)

/* State with the logged user data */
export const userData = atom(getUser())

/* State for the guest login on donation flow */
export const guestLogin = atom(false)

// State for the contact modal overlay
export const fabOverlayModal = atom(false)

export const fabOverlayModalMessage = atom(false)
